<template>
  <v-row justify="center">
    <v-dialog
      v-model="videoDialogState"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.presentation_videos') }}</span>
        </v-card-title>

        <v-container>
          <v-carousel
            v-model="carousel"
            :show-arrows="false"
            :hide-delimiters="true"
            height="400"
            ref="videowrapper"
          >
            <v-carousel-item v-for="(item, index) in carouselItems" :key="index">
              <h6>{{carouselTitles[index]}}</h6>
              <youtube :player-width="videoWidth" :video-id="item"></youtube>
            </v-carousel-item>
          </v-carousel>
        </v-container>

        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="6">
              <div class="mr-xxl-2" v-if="user && !user.watchedVideo">
                <v-checkbox
                  v-model="videoCheckbox"
                  :label="$t('message.no_more_videos')"
                  required
                ></v-checkbox>
              </div>
            </v-col>

            <v-col cols="12" sm="6" class="pb-8 px-xs-0 pb-xs-4 d-flex align-items-end">
              <v-btn plain color="primary" small :disabled="carousel === 0 " @click="carousel--">
                {{ $t('message.previous') }}
              </v-btn>

              <v-btn plain color="primary" small :disabled="carousel === 3" @click="carousel++">
                {{ $t('message.next') }}
              </v-btn>

              <v-btn plain color="black" small @click="closeVideoDialog()">
                {{ $t('message.skip') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'

export default {
  name: 'VideoDialog',
  data: () => ({
    videoCheckbox: null,
    successMessage: null,
    errors: [],
    videoWidth: 300,
    carouselItems: ['N7xRTXWxrrY', '2vyQUs11FMg', '7I5mPFqugms', 'AV9YtbmuBT4'],
    carousel: 0
  }),
  props: {
    videoDialogState: Boolean
  },
  mounted () {
    if (this.$refs.videowrapper) {
      this.videoWidth = this.$refs.videowrapper.$el.clientWidth
    } else {
      this.videoWidth = 300
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    carouselTitles () {
      return [
        this.$t('message.carousel_title_1'),
        this.$t('message.carousel_title_2'),
        this.$t('message.carousel_title_3'),
        this.$t('message.carousel_title_4')
      ]
    }
  },
  methods: {
    putUser () {
      this.successMessage = null
      this.errors = []

      const data = {
        watchedVideo: this.videoCheckbox
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/users/' + this.user.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data.message
              this.errors = []
              this.$store.commit('setUser', data)
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response && response.status === 500) {
            this.errors.push({
              title: this.$t('message.user_update_error')
            })
          }
        })
    },
    closeVideoDialog () {
      if (this.videoCheckbox === true) {
        this.putUser()
      }

      this.$emit('closeVideoDialog', false)
    }
  }
}
</script>
