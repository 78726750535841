import Vue from 'vue'
import config from '@/config'

const getDefaultState = () => {
  return {
    title: '',
    publishDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}:00`,
    list: [],
    documentCsv: {},
    documentTitle: '',
    safeDocument: {},
    xTarget: null
  }
}

const massiveUpload = {
  namespaced: true,
  state: getDefaultState(),
  getters: { },
  mutations: {
    resetState: (state) => {
      Object.assign(state, getDefaultState())
      state.xTarget = localStorage.getItem('x_target')
    },
    updateTitle: (state, val) => {
      state.title = val
    },
    updateDocumentCsv: (state, val) => {
      state.documentCsv = val
    },
    updateDocumentTitle: (state, val) => {
      state.documentTitle = val
    },
    updateSafeDocument: (state, val) => {
      state.safeDocument = val
    },
    updateList: (state, value) => {
      state.list = value
    },
    updateXTarget: (state, value) => {
      localStorage.setItem('x_target', value)
      state.xTarget = value
    }
  },
  actions: {
    getData ({ commit, dispatch, rootGetters }) {
      commit('resetState')
      Vue.prototype.$http
        .get(config.apiUrl + '/massiveupload', {
          headers: {
            Authorization: 'Bearer ' + rootGetters.getToken
          }
        })
        .then(response => {
          if (Array.isArray(response.data)) {
            commit('updateList', response.data)
          } else {
            commit('updateTitle', response.data.title)
            commit('updateDocumentCsv', response.data.title)
            commit('updateDocumentTitle', response.data.documentNameVault)
            commit('updateSafeDocument', response.data.title)

            this.loading = false
          }

          commit('updateXTarget', response.headers['x-target'])
        })
        .catch(e => {
          const response = e.response

          this.loading = false

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        })
    }
  }
}

export default massiveUpload
