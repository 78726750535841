<template>
  <div id="uploads-list" class="animated--grow-in">
    <h4>
      <i class="fas fa-cloud-upload-alt"></i><span>{{ $t('message.files_import') }}</span>
      <div class="controls">
        <i class="mdi mdi-chevron-down" @click="onMouseClickToggleList()"></i>
        <i class="mdi mdi-close" @click="onMouseClickCloseList()"></i>
      </div>
    </h4>
    <ul>
      <li
        v-for="(document, documentIndex) in documentsInProgress"
        :key="'q' + documentIndex"
      >
        <i class="far fa-file-alt"></i>
        <span>{{ truncate(document.name) }}</span>
        <div v-if="document.status !== 'error'" :class='"progress-circle p" + document.uploadId'></div>
        <div v-if="document.status === 'error'" :class='"progress-circle"'>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red">
                fas fa-times
              </v-icon>
              <v-icon color="red" dark v-bind="attrs" v-on="on">
                fa-info-circle
              </v-icon>
            </template>
            <div>
              <span v-for="(error, errorIndex) in document.errors" :key="'e' + errorIndex">
                {{ error.title }}
              </span>
            </div>
          </v-tooltip>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DocumentsInProgress',
  computed: {
    documentsInProgressState () {
      return this.$store.getters.getDocumentsInProgressState
    },
    documentsInProgress () {
      return this.$store.getters.getDocumentsInProgress
    }
  },
  sockets: {
    documentTransferring (document) {
      const currentDocument = this.$store.getters.getDocumentInProgressById(document.uploadId)
      const documentProgressCircle = window.document.querySelector('.p' + currentDocument.uploadId)

      setTimeout(() => {
        documentProgressCircle.innerHTML = '<div class="dot-flashing"></div>'
      }, 2000)
    },
    documentPending (document) {
      const currentDocument = this.$store.getters.getDocumentInProgressById(document.uploadId)

      if (currentDocument) {
        const documentProgressCircle = window.document.querySelector('.p' + currentDocument.uploadId)

        setTimeout(() => {
          documentProgressCircle.innerHTML = '<div class="dot-flashing"></div>'
        }, 2000)
      }
    },
    documentDone (document) {
      const currentDocument = this.$store.getters.getDocumentInProgressById(document.uploadId)

      if (currentDocument) {
        const documentProgressCircle = window.document.querySelector('.p' + currentDocument.uploadId)

        setTimeout(() => {
          documentProgressCircle.innerHTML = '<i class="fas fa-check green--text"></i>'
        }, 2000)
      }
    },
    documentError (document) {
      const currentDocument = this.$store.getters.getDocumentInProgressById(document.uploadId)

      if (currentDocument) {
        const documentProgressCircle = window.document.querySelector('.p' + currentDocument.uploadId)

        setTimeout(() => {
          documentProgressCircle.innerHTML = '<i class="fas fa-times red--text"></i>'
        }, 2000)
      }
    }
  },
  methods: {
    onMouseClickCloseList () {
      for (let i = 0; this.documentsInProgress.length > i; i++) {
        if (this.documentsInProgress[i].status !== 'error') {
          if (parseInt(this.documentsInProgress[i].progress) !== 100) {
            return
          }
        }
      }

      this.$store.commit('setDocumentsInProgressState', false)
      this.$store.commit('setDocumentsInProgress', [])
    },
    onMouseClickToggleList () {
      const dropList = document.querySelector('#uploads-list')

      dropList.classList.toggle('is-reduced')
    },
    truncate (name) {
      if (name.length > 25) {
        return name.substring(0, 25) + '...'
      }

      return name
    }
  },
  watch: {
    deep: true,
    documentsInProgressState (value) {
      const dropList = document.querySelector('#uploads-list')

      if (this.documentsInProgressState === true) {
        document.querySelector('#content').classList.remove('is-dragover')

        dropList.classList.add('is-visible')
      } else {
        dropList.classList.remove('is-visible')

        this.$store.commit('setDocumentsInProgress', [])
      }
    }
  }
}
</script>
