<template>
  <v-snackbar :timeout="timeout" v-model="snackbar" tile color="red lighten-1" :multi-line="false">
    {{text}}
    <template v-slot:action="{ attrs }">
      <v-btn icon color="white" text v-bind="attrs" @click="snackbar = false"><i class="fa fa-times"></i></v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: 'notification',
  props: {
    text: String,
    title: String,
    lastId: Number,
    type: {
      type: String,
      default: 'info',
      validator: value => {
        const acceptedValues = [
          'info',
          'primary',
          'danger',
          'warning',
          'success'
        ]
        return acceptedValues.indexOf(value) !== -1
      }
    },
    timeout: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      snackbar: true
    }
  },
  watch: {
    snackbar: function (e) {
      if (!e) {
        this.$store.dispatch('messages/removeMessage', this.lastId)
      }
    }
  }
}
</script>
<style>
.v-snack.v-snack--active {
  height: auto!important;
  position: relative!important;
}
</style>
