document.querySelectorAll('.autocomplete').forEach(input => {
  input.addEventListener('focus', function () {
    input.parentNode.querySelector('.autocomplete-wrap').classList.add('show')
  })
})

document.querySelectorAll('.autocomplete').forEach(input => {
  input.addEventListener('blur', function () {
    input.parentNode.querySelector('.autocomplete-wrap').classList.remove('show')
  })
})

document.querySelectorAll('.autocomplete-wrap').forEach(list => {
  list.children.forEach(item => {
    item.addEventListener('mousedown', function () {
      const input = item.parentNode.parentNode.querySelector('.autocomplete')
      if (!input.value) {
        input.value = item.textContent
      }
      if (input.value && !input.value.includes(item.textContent)) {
        input.value += ' + ' + item.textContent
      }
      input.parentNode.classList.add('is-dirty')
    })
  })
})

document.querySelectorAll('.mdl-textfield-custom .btn-clear').forEach(btn => {
  btn.addEventListener('mousedown', function () {
    const input = btn.parentNode.querySelector('input')
    input.parentNode.classList.remove('is-dirty')
    input.value = ''
  })
})
