<template>
  <div class="notification-wrapper" v-if="$store.state.messages.messages.length">
      <notification
          v-for="notification in $store.state.messages.messages"
          :key="notification.id"
          :lastId="notification.id"
          :text="notification.text"
          :timeout="notification.time"
          :type="notification.type"
      >
      </notification>
    </div>
</template>
<script>
import Notification from './Notification.vue'

export default {
  components: {
    Notification
  }
}
</script>

<style>
.notification-wrapper{
  position: fixed;
  right: 0;
  bottom: 25px;
  z-index: 500;
}
</style>
