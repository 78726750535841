import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import jwtDecode from 'jwt-decode'
import slugify from 'slugify'
import config from '../config'
import releaselog from './releaselog'
import massiveUpload from './massiveUpload'
import messages from './messages'
import dashboardStore from './Dashboard/dashboardStore'
import storageStore from './storageStore'
import usersVault from './usersVault'
import statistics from './statistics'

import page from './page'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    storageStore: storageStore,
    dashboardStore: dashboardStore,
    page,
    releaselog: releaselog,
    massiveUpload,
    messages,
    usersVault,
    statistics
  },
  state: {
    windowWidth: window.innerWidth,
    authenticated: false,
    error: null,
    user: null,
    email: null,
    token: null,
    tokenExpiration: null,
    tempToken: null,
    locale: 'fr',
    availableLocales: ['fr', 'en'],
    companies: [],
    currentCompany: null,
    vaults: [],
    currentVault: null,
    currentRootFolders: [],
    currentRootFolder: null,
    trashes: [],
    folders: [],
    documents: [],
    secrets: [],
    documentsInProgress: [],
    documentsInProgressState: false,
    users: [],
    roles: [],
    habilitations: [],
    lastRefresh: null,
    vaultDiskUsage: 0,
    numberOfFiles: 0,
    ignoredUsageMsg: false,
    handlingDocumentScroll: false,
    handlingSecretScroll: false,
    handlingLogScroll: false,
    handlingTrashScroll: false,
    handlingUserScroll: false,
    handlingVaultUserScroll: false,
    xTarget: null
  },
  mutations: {
    initStore: state => {
      state.token = localStorage.getItem('token')
      state.tokenExpiration = localStorage.getItem('token_expiration')
      state.tempToken = localStorage.getItem('temp_token')
      state.user = JSON.parse(localStorage.getItem('user'))
      state.email = localStorage.getItem('email')
      state.companies = JSON.parse(localStorage.getItem('companies'))
      state.currentCompany = JSON.parse(localStorage.getItem('current_company'))
      state.vaults = JSON.parse(localStorage.getItem('vaults'))
      state.currentVault = JSON.parse(localStorage.getItem('current_vault'))
      state.currentRootFolders = JSON.parse(localStorage.getItem('current_root_folders'))
      state.roles = JSON.parse(localStorage.getItem('roles'))
      state.habilitations = JSON.parse(localStorage.getItem('habilitations'))
      state.lastRefresh = localStorage.getItem('last_refresh')
      state.vaultDiskUsage = JSON.parse(localStorage.getItem('vault_disk_usage'))
      state.numberOfFiles = JSON.parse(localStorage.getItem('number_of_files'))
      state.ignoredUsageMsg = localStorage.getItem('ignored_usage_msg')
      state.xTarget = localStorage.getItem('x_target')
      state.locale = localStorage.getItem('locale') || 'fr'
    },
    setWindowWidth: state => {
      state.windowWidth = window.innerWidth
    },
    setAuthenticated: (state, authenticated) => {
      state.authenticated = authenticated
    },
    setToken: (state, token) => {
      localStorage.setItem('token', token)
      state.token = token
    },
    setTokenExpiration: (state, token) => {
      const tokenExpiration = token ? Date.now() + (3300 * 1000) : 0

      localStorage.setItem('token_expiration', tokenExpiration)
      state.tokenExpiration = tokenExpiration
    },
    setTempToken: (state, tempToken) => {
      localStorage.setItem('temp_token', tempToken)
      state.tempToken = tempToken
    },
    setError: (state, error) => {
      state.error = error
    },
    setUser: (state, user) => {
      localStorage.setItem('user', user ? JSON.stringify(user) : null)
      state.user = user
    },
    setEmail: (state, email) => {
      localStorage.setItem('email', email)
      state.email = email
    },
    setVaults: (state, vaults) => {
      localStorage.setItem('vaults', vaults ? JSON.stringify(vaults) : [])
      state.vaults = vaults
    },
    setCurrentVault: (state, vault) => {
      localStorage.setItem('current_vault', vault ? JSON.stringify(vault) : null)
      state.currentVault = vault
    },
    setCompanies: (state, companies) => {
      localStorage.setItem('companies', companies ? JSON.stringify(companies) : [])
      state.companies = companies
    },
    setCurrentCompany: (state, company) => {
      localStorage.setItem('current_company', company ? JSON.stringify(company) : null)
      state.currentCompany = company
    },
    setCurrentRootFolders: (state, currentRootFolders) => {
      localStorage.setItem('current_root_folders', currentRootFolders ? JSON.stringify(currentRootFolders) : [])
      state.currentRootFolders = currentRootFolders
    },
    setCurrentRootFolder: (state, currentRootFolder) => {
      state.currentRootFolder = currentRootFolder
    },
    setFolders: (state, folders) => {
      folders.length ? state.folders.push(...folders) : state.folders = folders
    },
    addFolder: (state, folder) => {
      state.folders.push(folder)
    },
    deleteFolder: (state, folder) => {
      state.folders = state.folders.filter(element => element.id !== folder.id)
    },
    deleteFolders: (state, folders) => {
      const folderIds = folders.map(element => element.id)

      state.folders = state.folders.filter(element => !folderIds.includes(element.id))
    },
    setDocuments: (state, documents) => {
      documents.length ? state.documents.push(...documents) : state.documents = documents
    },
    addDocument: (state, document) => {
      state.documents.push(document)
    },
    deleteDocument: (state, document) => {
      state.documents = state.documents.filter(element => element.id !== document.id)
    },
    deleteDocuments: (state, documents) => {
      const documentIds = documents.map(element => element.id)

      state.documents = state.documents.filter(element => !documentIds.includes(element.id))
    },
    setSecrets: (state, secrets) => {
      secrets.length ? state.secrets.push(...secrets) : state.secrets = secrets
    },
    addSecret: (state, secret) => {
      state.secrets.push(secret)
    },
    deleteSecret: (state, secret) => {
      state.secrets = state.secrets.filter(element => element.id !== secret.id)
    },
    deleteSecrets: (state, secrets) => {
      const secretIds = secrets.map(element => element.id)

      state.secrets = state.secrets.filter(element => !secretIds.includes(element.id))
    },
    setTrashes: (state, trashes) => {
      trashes.length ? state.trashes.push(...trashes) : state.trashes = trashes
    },
    addTrash: (state, trash) => {
      state.trashes.push(trash)
    },
    deleteTrash: (state, trash) => {
      state.trashes = state.trashes.filter(element => element.id !== trash.id)
    },
    deleteTrashes: (state, trashes) => {
      const trashIds = trashes.map(element => element.id)

      state.trashes = state.trashes.filter(element => !trashIds.includes(element.id))
    },
    setDocumentsInProgress: (state, documents) => {
      state.documentsInProgress = documents
    },
    setDocumentsInProgressState: (state, status) => {
      state.documentsInProgressState = status
    },
    addDocumentInProgress: (state, document) => {
      state.documentsInProgress.push(document)
    },
    deleteDocumentInProgress: (state, document) => {
      state.documentsInProgress = state.documentsInProgress.filter(element => element.id !== document.id)
    },
    addUsers: (state, users) => {
      state.users.push(...users)
    },
    setUsers: (state, users) => {
      state.users = users
    },
    setRoles: (state, roles) => {
      localStorage.setItem('roles', roles ? JSON.stringify(roles) : [])
      state.roles = roles
    },
    setHabilitations: (state, habilitations) => {
      localStorage.setItem('habilitations', habilitations ? JSON.stringify(habilitations) : [])
      state.habilitations = habilitations
    },
    setLastRefresh: (state, lastRefresh) => {
      localStorage.setItem('last_refresh', lastRefresh)
      state.lastRefresh = lastRefresh
    },
    setVaultDiskUsage: (state, vaultDiskUsage) => {
      localStorage.setItem('vault_disk_usage', vaultDiskUsage ? JSON.stringify(vaultDiskUsage) : null)
      state.vaultDiskUsage = vaultDiskUsage
    },
    setNumberOfFiles: (state, numberOfFiles) => {
      localStorage.setItem('number_of_files', numberOfFiles ? JSON.stringify(numberOfFiles) : null)
      state.numberOfFiles = numberOfFiles
    },
    setIgnoredUsageMsg: (state, status) => {
      localStorage.setItem('ignored_usage_msg', status)
      state.ignoredUsageMsg = status
    },
    setHandlingDocumentScroll: (state, status) => {
      state.handlingDocumentScroll = status
    },
    setHandlingSecretScroll: (state, status) => {
      state.handlingSecretScroll = status
    },
    setHandlingLogScroll: (state, status) => {
      state.handlingLogScroll = status
    },
    setHandlingTrashScroll: (state, status) => {
      state.handlingTrashScroll = status
    },
    setHandlingUserScroll: (state, status) => {
      state.handlingUserScroll = status
    },
    setHandlingVaultUserScroll: (state, status) => {
      state.handlingVaultUserScroll = status
    },
    setXTarget: (state, xTarget) => {
      localStorage.setItem('x_target', xTarget)
      state.xTarget = xTarget
    },
    setLocale: (state, locale) => {
      localStorage.setItem('locale', locale)
      state.locale = locale
    }
  },
  actions: {
    socketLogin (state, user) {
      this._vm.$socket.client.emit('login', user)
    },
    socketLogout (state) {
      this._vm.$socket.client.emit('logout')
    },
    socketLoaded (state, user) {
      this._vm.$socket.client.emit('loaded', user)
    },
    login: async ({ dispatch, state, commit }, data) => {
      const email = data.email

      return Vue.prototype.$http
        .post(config.apiUrl + '/login', data)
        .then(response => {
          const data = response.data

          if (response.status === 200 && data.success !== false) {
            const token = data.token
            const decodedTokenData = jwtDecode(token)

            commit('setToken', token)
            commit('setTokenExpiration', token)

            if (!decodedTokenData.hasUpdatedPassword) {
              commit('setUser', decodedTokenData)
              router.push({ name: 'UpdateCurrentPassword' })
            } else {
              commit('setAuthenticated', true)
              commit('setError', null)
              commit('setLastRefresh', Date.now())

              dispatch('getUser').then(() => {
                dispatch('releaselog/getData', { uri: null, user: state.user.id }, { root: true })
              })
            }
          }

          if (response.status === 200 && data.success === false) {
            const tempToken = data.temp_token

            commit('setTempToken', tempToken)
            commit('setEmail', email)

            router.push({ name: 'TwoFactorLogin' })
          }
        })
        .catch(e => {
          const response = e.response

          if (!response) {
            commit('setError', 'Erreur réseau')

            return e
          }

          const data = response.data

          if (response.status === 401) {
            let message = data.message

            if (message === 'Invalid credentials.') {
              message = 'Identifiants incorrects.'
            }

            if (message === 'Account is disabled.') {
              message = 'Compte inactif.'
            }

            if (message === 'Account is locked.') {
              message = 'Compte bloqué pour 15min.'
            }

            commit('setError', message)
          }

          return e
        })
    },
    twoFactorLogin: ({ dispatch, state, commit }, data) => {
      Vue.prototype.$http
        .post(config.cff2faUrl, data)
        .then(response => {
          if (response.status === 200) {
            const data = response.data
            const token = data.token
            const decodedTokenData = jwtDecode(token)

            commit('setToken', token)
            commit('setTokenExpiration', token)
            commit('setLocale', decodedTokenData.locale)

            if (!decodedTokenData.hasUpdatedPassword) {
              commit('setUser', decodedTokenData)
              router.push({ name: 'UpdateCurrentPassword' })
            } else {
              commit('setAuthenticated', true)
              commit('setError', null)
              commit('setLastRefresh', Date.now())

              dispatch('getUser').then(() => {
                dispatch('releaselog/getData', { uri: null, user: state.user.id }, { root: true })
              })
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (!response) {
            commit('setError', 'Erreur réseau')

            return
          }

          commit('setError', response.data)
        })
    },
    loginCas: ({ dispatch, state, commit }, data) => {
      commit('setToken', data.data.token)
      commit('setTokenExpiration', data.data.token)
      commit('setAuthenticated', true)
      commit('setError', null)
      commit('setLastRefresh', Date.now())

      const tokenData = jwtDecode(data.data.token)

      dispatch('getUser').then(() => {
        dispatch('releaselog/getData', { uri: null, user: tokenData.userId }, { root: true })
      })
    },
    loginAUT: ({ dispatch, state, commit }, data) => {
      commit('setToken', data.token)
      commit('setTokenExpiration', data.token)
      commit('setAuthenticated', true)
      commit('setError', null)
      commit('setLastRefresh', Date.now())

      dispatch('getUser').then(() => {
        dispatch('releaselog/getData', { uri: null, user: state.user.id }, { root: true })
      })
    },
    logout: (state, type) => {
      if (type !== 'rejected') {
        Vue.prototype.$http
          .get(config.apiUrl + '/logout', {
            headers: {
              Authorization: 'Bearer ' + state.getters.getToken
            },
            params: {
              type: type
            }
          })
          .then(response => {
            state.commit('setToken', null)
          })
          .catch(e => {
            state.commit('setToken', null)
          })
      }

      const isBudgetUser = state.getters.isBudgetUser
      state.commit('setTokenExpiration', null)
      state.commit('setUser', null)
      state.commit('setEmail', null)
      state.commit('setTempToken', null)
      state.commit('setAuthenticated', false)
      state.commit('setVaults', [])
      state.commit('setCurrentVault', null)
      state.commit('setCompanies', [])
      state.commit('setCurrentCompany', null)
      state.commit('setCurrentRootFolders', [])
      state.commit('setDocumentsInProgress', [])
      state.commit('setDocumentsInProgressState', false)
      state.commit('setRoles', [])
      state.commit('setHabilitations', [])
      state.commit('setLastRefresh', null)
      state.commit('setVaultDiskUsage', null)
      state.commit('setNumberOfFiles', null)
      state.commit('setIgnoredUsageMsg', false)
      state.commit('setXTarget', null)

      if (type === 'timeout' && isBudgetUser) {
        router.push({ name: 'LoginBudget' })
      } else if (type === 'timeout') {
        router.push({ name: 'Login', query: { timeout: 'true' } })
      } else if (type === 'rejected') {
        router.push({ name: 'Login', query: { rejected: 'true' } })
      } else if (isBudgetUser) {
        router.push({ name: 'LoginBudget' })
      } else if (type === 'rcu') {
        state.dispatch('socketLogout')
        location.href = config.logoutUrl
      } else {
        router.push({ name: 'Login' })
      }

      state.dispatch('socketLogout')
    },
    refreshToken: (state) => {
      Vue.prototype.$http
        .get(config.apiUrl + '/refresh-token', {
          headers: {
            Authorization: 'Bearer ' + state.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              state.commit('setToken', data.token)
              state.commit('setTokenExpiration', data.token)
              state.commit('setLastRefresh', Date.now())
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response && response.status === 401) {
            state.dispatch('logout', 'timeout')
          }
        })
    },
    getUser: (state) => {
      const token = state.getters.getToken
      const data = jwtDecode(token)

      if (data.userHabilitations) {
        state.commit('setHabilitations', data.userHabilitations)
      }

      return Vue.prototype.$http
        .get(config.apiUrl + '/users/' + data.userId, {
          headers: {
            Authorization: 'Bearer ' + state.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const user = response.data
            const vaults = user.vaults
            const companies = user.companies

            state.commit('setUser', user)
            state.commit('setVaults', vaults)
            state.commit('setCompanies', companies)
            state.commit('setCurrentCompany', companies[0])

            state.dispatch('socketLogin', user)

            if (vaults.length) {
              const vault = vaults[0]
              const company = vault.company
              state.commit('setCurrentCompany', company)

              state.commit('setCurrentVault', vault)

              state.dispatch('getVaultDiskUsage', vault)
              state.dispatch('getNumberOfFiles', vault)
              state.dispatch('getCurrentRootFolders')
            } else if (state.getters.hasProfile('MASTER_KEY')) {
              router.push({ name: 'SetMasterKey' })
            } else if (user.rcu === true) {
              router.push({ name: 'Logs' })
            } else if (state.getters.hasProfile('ADMIN_BUDGET')) {
              router.push({ name: 'BudgetCodeUsers' })
            } else {
              router.push({ name: 'Users' })
            }
          }
        })
        .catch(e => {
        })
    },
    setRoute: (state) => {
      state.dispatch('getCurrentRootFolders')

      // if the user is client
      if (state.getters.getUser.rcu || state.getters.isBudgetUser) {
        const firstRootFolder = this.currentRootFolders[0]
        const rootFolderSlug = slugify(firstRootFolder.name, { lower: true })

        router.push({ name: 'DocumentList', params: { rootFolderName: rootFolderSlug }, query: { folder: firstRootFolder.id } })

        return
      }

      // if the user is from Fiducial
      if (router.history.current.name === 'Home') {
        router.push({ name: 'Home', query: { _: new Date().getTime() } })

        router.replace({ name: 'Home' })
      } else {
        router.push({ name: 'Home' })
      }
    },
    getCurrentRootFolders: (state) => {
      const currentVault = state.getters.getCurrentVault

      if (!currentVault) {
        return
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/folders', {
          headers: {
            Authorization: 'Bearer ' + state.getters.getToken
          },
          params: {
            vault: currentVault.id,
            root: true
          }
        })
        .then(response => {
          if (response.status === 200) {
            const currentRootFolders = response.data

            state.commit('setCurrentRootFolders', currentRootFolders)

            const firstRootFolder = currentRootFolders[0]

            if (!firstRootFolder) {
              router.push({ name: 'Logs' })
            } else if (state.getters.getUser.rcu || state.getters.isBudgetUser) {
              const rootFolderSlug = slugify(firstRootFolder.name, { lower: true })
              router.push({ name: 'DocumentList', params: { rootFolderName: rootFolderSlug }, query: { folder: firstRootFolder.id } })
            } else {
              router.push({ name: 'Home' })
            }
          }
        })
        .catch(e => {
        })
    },
    updateCurrentRootFolder: (state) => {
      const currentRootFolders = state.getters.getCurrentRootFolders
      const routeParams = router.currentRoute.params
      const currentRootFolderName = routeParams.rootFolderName

      if (!currentRootFolderName) {
        state.commit('setCurrentRootFolder', null)
      }

      for (let i = 0; i < currentRootFolders.length; i++) {
        const currentRootFolder = currentRootFolders[i]
        const rootFolderName = currentRootFolder.name

        if (slugify(rootFolderName, { lower: true }) === currentRootFolderName) {
          state.commit('setCurrentRootFolder', currentRootFolder)
        }
      }
    },
    getVaultDiskUsage: (state, vault) => {
      Vue.prototype.$http
        .get(config.apiUrl + '/section_usages', {
          headers: {
            Authorization: 'Bearer ' + state.getters.getToken
          },
          params: {
            vault: vault.id
          }
        })
        .then(response => {
          if (response.status === 200) {
            const vaultUsageData = response.data

            state.commit('setVaultDiskUsage', vaultUsageData)
          }
        })
        .catch(e => {
        })
    },
    getNumberOfFiles: (state, vault) => {
      Vue.prototype.$http
        .get(config.apiUrl + '/documents', {
          headers: {
            Authorization: 'Bearer ' + state.getters.getToken
          },
          params: {
            vault: vault.id,
            count: true
          }
        })
        .then(response => {
          if (response.status === 200) {
            const numberOfFiles = response.data

            state.commit('setNumberOfFiles', numberOfFiles)
          }
        })
        .catch(e => {
        })
    },
    updateXTarget (state, xTarget) {
      state.commit('setXTarget', xTarget)
    }
  },
  getters: {
    isBudgetUser: (state) => {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('type') && urlParams.get('type') === 'cfrb') return true
      if (!state.currentVault) return false
      if (!state.currentVault.vaultsRole) return false
      const vaultsRole = state.currentVault.vaultsRole
      for (let i = 0; i < vaultsRole.length; i++) {
        if (vaultsRole[i].profiles.includes('USER')) {
          return true
        }
      }
      return false
    },
    getWindowWidth: state => {
      return state.windowWidth
    },
    isAuthenticated: (state, getters) => {
      return !getters.isTokenExpired
    },
    getToken: state => {
      return state.token
    },
    getTempToken: state => {
      return state.tempToken
    },
    isTokenExpired: state => {
      return Date.now() > state.tokenExpiration
    },
    getError: state => {
      return state.error
    },
    getUser: state => {
      return state.user
    },
    getEmail: state => {
      return state.email
    },
    getCompanies: state => {
      return state.companies
    },
    getCurrentCompany: state => {
      return state.currentCompany
    },
    getVaults: state => {
      return state.vaults
    },
    getCurrentVault: state => {
      return state.currentVault
    },
    getCurrentRootFolders: state => {
      return state.currentRootFolders
    },
    getCurrentRootFolder: state => {
      return state.currentRootFolder
    },
    getFolders: state => {
      return state.folders
    },
    getDocuments: state => {
      return state.documents
    },
    getDocumentsInProgress: state => {
      return state.documentsInProgress
    },
    getDocumentInProgressById: state => id => {
      return state.documentsInProgress.find(element => element.uploadId === id)
    },
    getDocumentsInProgressState: state => {
      return state.documentsInProgressState
    },
    getSecrets: state => {
      return state.secrets
    },
    getTrashes: state => {
      return state.trashes
    },
    getUsers: state => {
      return state.users
    },
    getHabilitations: state => {
      return state.habilitations
    },
    getLastRefresh: state => {
      return state.lastRefresh
    },
    getVaultDiskUsage: state => {
      return state.vaultDiskUsage
    },
    getNumberOfFiles: state => {
      return state.numberOfFiles
    },
    hasIgnoredUsageMsg: state => {
      return state.ignoredUsageMsg
    },
    isHandlingDocumentScroll: state => {
      return state.handlingDocumentScroll
    },
    isHandlingSecretScroll: state => {
      return state.handlingSecretScroll
    },
    isHandlingLogScroll: state => {
      return state.handlingLogScroll
    },
    isHandlingTrashScroll: state => {
      return state.handlingTrashScroll
    },
    isHandlingUserScroll: state => {
      return state.handlingUserScroll
    },
    getXTarget: state => {
      return state.xTarget
    },
    getCompaniesWhereAdmin: state => {
      const companies = []

      for (let i = 0; i < state.companies.length; i++) {
        if (state.companies[i].profiles.includes('ADMIN') ||
          state.companies[i].profiles.includes('SUPPORT') ||
          state.companies[i].profiles.includes('LEADER')
        ) {
          companies.push(state.companies[i].company)
        }
      }

      return companies
    },
    hasProfile: (state, getters) => (profile) => {
      const companies = state.companies

      for (let i = 0; i < companies.length; i++) {
        if (companies[i].profiles.includes(profile)) {
          return true
        }
      }

      return false
    },
    hasRole: (state, getters) => (role, company = null) => {
      if (company) {
        const selectedCompany = state.companies.filter(element => element.company.id === company)

        if (selectedCompany.length === 0) {
          return false
        }

        if (selectedCompany[0].roles.includes(role)) {
          return true
        }

        return false
      }

      const companies = state.companies

      for (let i = 0; i < companies.length; i++) {
        if (companies[i].roles.includes(role)) {
          return true
        }
      }

      return false
    },
    isCasUser: (state, getters) => {
      if ((getters.isAuthenticated && state.currentCompany.externalId === 'f-budgets') || window.location.hostname.includes('fiducial.dom')) {
        return true
      }
      return false
    },
    getLocale: state => {
      return state.locale.toUpperCase()
    },
    getAvailableLocales: state => {
      return state.availableLocales
    }
  }
})
