import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export const budgetCodeTheme = {
  primary: '#0A93A2',
  anchor: '#0A93A2'
}

export const primaryTheme = {
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FB8C00'
}

export default new Vuetify({
  options: {
    customProperties: true
  },
  theme: {
    themes: {
      budgetCode: budgetCodeTheme
    }
  }
})
