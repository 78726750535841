import Vue from 'vue'
import VueRouter from 'vue-router'
import DefaultLayout from '../layouts/DefaultLayout'
import LoginLayout from '../layouts/LoginLayout'
import slugify from 'slugify'

Vue.use(VueRouter)

const routes = [
  {
    path: '/connexion-code-budget',
    name: 'LoginBudget',
    component: () => import('../views/Security/LoginBudgetCode.vue'),
    meta: {
      layout: LoginLayout,
      budgetCode: true
    }
  },
  {
    path: '/connexion-code-budget-callback',
    name: 'LoginBudgetCallback',
    component: () => import('../views/Security/LoginBudgetCodeCallback.vue'),
    meta: {
      layout: LoginLayout,
      budgetCode: true
    }
  },
  {
    path: '/2fa-qr-code',
    name: 'TwoFactorQrCode',
    component: () => import('../views/Security/TwoFactorQrCode.vue'),
    meta: {
      layout: DefaultLayout
    }
  },
  {
    path: '/status',
    name: 'Status',
    component: () => import('../views/Security/Status.vue')
  },
  {
    path: '/connexion',
    name: 'Login',
    component: () => import('../views/Security/Login.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/connexion-double-facteurs',
    name: 'TwoFactorLogin',
    component: () => import('../views/Security/TwoFactorLogin.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/qr-code-perdu',
    name: 'LostQrCode',
    component: () => import('../views/Security/LostQrCode.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/reinitialisation-qr-code',
    name: 'ResetQrCode',
    component: () => import('../views/Security/ResetQrCode.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/entry',
    name: 'Entry',
    component: () => import('../views/Security/Entry.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('../views/Security/Callback.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/deconnexion',
    name: 'Logout',
    component: () => import('../views/Security/Login.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/mot-de-passe-oublie',
    name: 'ForgotPassword',
    component: () => import('../views/Security/ForgotPassword.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/reinitialisation-mot-de-passe',
    name: 'ResetPassword',
    component: () => import('../views/Security/ResetPassword.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/mise-a-jour-mot-de-passe',
    name: 'UpdateCurrentPassword',
    component: () => import('../views/Security/UpdateCurrentPassword.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/modification-mot-de-passe',
    name: 'UpdatePassword',
    component: () => import('../views/Security/UpdatePassword.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/modification-email',
    name: 'UpdateEmail',
    component: () => import('../views/Security/UpdateEmail.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/validation-email',
    name: 'ValidateEmail',
    component: () => import('../views/Security/ValidateEmail.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/activation/etape-1',
    name: 'AccountSetupStep1',
    component: () => import('../views/Security/AccountSetupStep1.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/activation/etape-2',
    name: 'AccountSetupStep2',
    component: () => import('../views/Security/AccountSetupStep2.vue'),
    meta: { layout: LoginLayout }
  },
  {
    path: '/admin/utilisateurs-code-budget',
    name: 'BudgetCodeUsers',
    component: () => import('../views/Admin/Collaborators.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/admin/utilisateurs',
    name: 'Users',
    component: () => import('../views/Admin/Users.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/admin/verification-cle-maitre',
    name: 'VerifyMasterKey',
    component: () => import('../views/Admin/VerifyMasterKey.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/admin/initialisation-cle-maitre',
    name: 'SetMasterKey',
    component: () => import('../views/Admin/SetMasterKey.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/admin/modification-cle-maitre',
    name: 'UpdateMasterKey',
    component: () => import('../views/Admin/UpdateMasterKey.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/mes-documents/corbeille',
    name: 'Trash',
    component: () => import('../views/Document/Trash.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/mes-documents/:rootFolderName',
    name: 'DocumentList',
    component: () => import('../views/Document/List.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/recherche/:searchString',
    name: 'Search',
    component: () => import('../views/Document/Search.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/mes-secrets',
    name: 'SecretList',
    component: () => import('../views/Secret/List.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/mes-secrets-entreprise',
    name: 'SecretList2',
    component: () => import('../views/Secret/List.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/options-de-notifications',
    name: 'NotificationsOptions',
    component: () => import('../views/Account/NotificationsOptions.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/tiers-de-confiance',
    name: 'TrustedContact',
    component: () => import('../views/Account/TrustedContact.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/souscription-aux-services',
    name: 'ServicesSubscription',
    component: () => import('../views/Account/ServicesSubscription.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/historique-des-actions',
    name: 'Logs',
    component: () => import('../views/Account/Logs.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/collaborateurs',
    name: 'Clearances',
    component: () => import('../views/Admin/Clearances.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/rights-management',
    name: 'RightsManagement',
    component: () => import('../views/Admin/RightsManagement.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Front/Faq.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Front/Contact.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/contact-budget',
    name: 'ContactBudget',
    component: () => import('../views/Front/ContactBudget.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/contact-validation',
    name: 'ContactValidation',
    component: () => import('../views/Front/ContactValidation.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/mentions-legales',
    name: 'LegalNotice',
    component: () => import('../views/Front/LegalNotice.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/cgu',
    name: 'TermsOfService',
    component: () => import('../views/Front/TermsOfService.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/politique-de-confidentialite',
    name: 'PrivacyPolicy',
    component: () => import('../views/Front/PrivacyPolicy.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/page',
    name: 'PageList',
    component: () => import('../views/Admin/PageList.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/page/:slug',
    name: 'Page',
    component: () => import('../views/Admin/Page.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/p/:slug',
    name: 'FOPage',
    component: () => import('../views/Front/FOPage.vue'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/gestion-des-nouveautes',
    name: 'ReleaselogList',
    component: () => import('../views/Admin/ReleaselogList'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/gestion-des-nouveautes/:slug',
    name: 'Releaselog',
    component: () => import('../views/Admin/Releaselog'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/depot-massif',
    name: 'MassiveUploadList',
    component: () => import('../views/MassiveUpload/MassiveUploadList'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/depot-massif/:slug',
    name: 'MassiveUpload',
    component: () => import('../views/MassiveUpload/MassiveUpload'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/gestion-des-statistiques',
    name: 'StatisticsMain',
    component: () => import('../views/Admin/Statistics/StatisticsMain'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/gestion-des-statistiques/coffres-graphique',
    name: 'VaultChart',
    component: () => import('../views/Admin/Statistics/VaultChart'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/gestion-des-statistiques/connexions-graphique',
    name: 'ConnectionChart',
    component: () => import('../views/Admin/Statistics/ConnectionChart'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/gestion-des-statistiques/documents-graphique',
    name: 'DocumentChart',
    component: () => import('../views/Admin/Statistics/DocumentChart'),
    meta: { layout: DefaultLayout }
  },
  {
    path: '/administration/gestion-des-statistiques/secrets-graphique',
    name: 'SecretChart',
    component: () => import('../views/Admin/Statistics/SecretChart'),
    meta: { layout: DefaultLayout }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// replace for environment on .env file
const blockedUrlBudgetCodeLogin = [
  'https://cfnv2-int.fidcl.cloud/connexion-code-budget',
  'https://cfnv2-rec.fiducialcloud.fr/connexion-code-budget',
  'https://cfnv2-ppr.fiducialcloud.fr/connexion-code-budget',
  'https://mon-ecoffre.com/connexion-code-budget'
]

router.beforeEach((to, from, next) => {
  if (blockedUrlBudgetCodeLogin.includes(window.location.href) && to.name !== 'Login') {
    next('/connexion')
  }
  const token = localStorage.getItem('token')
  const tokenExpiration = localStorage.getItem('token_expiration')
  const isTokenExpired = Date.now() > tokenExpiration
  const isAllowed = token && !isTokenExpired

  if ((to.name === 'Contact' ||
    to.name === 'ContactBudget' ||
    to.name === 'ContactValidation' ||
    to.name === 'Faq' ||
    to.name === 'LegalNotice' ||
    to.name === 'TermsOfService' ||
    to.name === 'PrivacyPolicy' ||
    to.name === 'ForgotPassword' ||
    to.name === 'ResetPassword' ||
    to.name === 'UpdateCurrentPassword' ||
    to.name === 'ValidateEmail' ||
    to.name === 'AccountSetupStep1' ||
    to.name === 'AccountSetupStep2' ||
    to.name === 'Status' ||
    to.name === 'Callback' ||
    to.name === 'Entry' ||
    to.name === 'Nouveautes' ||
    to.name === 'LoginBudget' ||
    to.name === 'LoginBudgetCallback' ||
    to.name === 'TwoFactorLogin' ||
    to.name === 'LostQrCode' ||
    to.name === 'ResetQrCode'
  ) && !isAllowed) {
    next()
  } else if (to.path !== '/connexion' && !isAllowed) {
    next('/connexion')
  } else {
    next()
  }
})

router.resolveHome = function (store) {
  // if (store.state.currentVault.budgetCode) {
  //   return {
  //     name: 'Home'
  //   }
  // }
  if (store.state.currentRootFolders.length) {
    return {
      name: 'DocumentList',
      params: {
        rootFolderName: slugify(store.state.currentRootFolders[0].name, { lower: true })
      },
      query: {
        folder: store.state.currentRootFolders[0].id
      }
    }
  }

  return {
    name: 'Home'
  }
}

export default router
