<template>
  <v-row justify="center">
    <v-dialog
      v-model="cguDialogState"
      persistent
      max-width="720px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.cgu_acceptance') }}</span>
        </v-card-title>
        <v-container>
          <v-card-text v-if="getLocale() === 'fr'">
            <div>
              <p>Les présentes Conditions Générales d’Utilisation (CGU) ont pour objet de définir les conditions
                d’utilisation du&nbsp;service de coffre-fort numérique dénommé Mon ecoffre pour la dématérialisation de
                documents.
                Ce service «&nbsp;Mon ecoffre&nbsp;» est ouvert aux seuls salariés des entreprises ayant conclues un Contrat
                de services avec FIDUCIAL Cloud. Ce Contrat est conclu en vue d’offrir la possibilité aux dits
                salariés de recevoir des documents professionnels, émis par leur employeur, relatifs aux Ressources
                Humaines au format numérique, par exemple, le Bulletin de Paie Electronique.</p>
              <br>
              <h4>PREAMBULE : DEFINITIONS</h4>
              <p><strong>FIDUCIAL Cloud ou FIDUCIAL</strong>&nbsp;: &nbsp;désigne la société FIDUCIAL Cloud, Société par actions
                simplifiée au capital de 1.249.597,16 euros, dont le siège social est situé 41 rue du Capitaine
                Guynemer – 92400 Courbevoie, immatriculée au Registre du Commerce et des Sociétés de Nanterre, sous
                le numéro 480 650 712, société appartenant au Groupe Fiducial et éditrice du Site.</p>
              <p><strong>Compte</strong>&nbsp;: désigne l’espace personnel mis à disposition de l’Utilisateur par FIDUCIAL
                Cloud auquel l’Utilisateur accède par un identifiant (sous la forme d’une adresse e-mail) et un mot
                de passe.</p>
              <p><strong>Utilisateur</strong>&nbsp;: désigne le salarié d’une entreprise ayant conclu un contrat avec
                FIDUCIAL Cloud relatif au service Mon ecoffre.</p>
              <p><strong>Site</strong>&nbsp;: désigne le site Internet édité par FIDUCIAL Cloud accessible à l’URL
                suivante&nbsp;: https://www.mon-ecoffre.com</p>
              <p><strong>Services</strong>&nbsp;: désignent la mise à disposition du Site et les services décrits à
                l’article 2.</p>
            </div>
            <div>
              <h4>ARTICLE 1 : OBJET – ACCEPTATION</h4>
              <p>En accédant au Site, l’Utilisateur reconnait avoir pris connaissance des présentes CGU et s’engage à
                les respecter. Toute connexion au Site, à l’aide d’un identifiant et d’un mot de passe, emporte
                pleine et entière acceptation des présentes CGU.</p>
              <p><strong>Modification des CGU</strong> – FIDUCIAL Cloud se réserve le droit de modifier les présentes
                CGU à tout moment.
                L’Utilisateur s’engage donc à les consulter régulièrement.</p>
            </div>
            <div>
              <h4>ARTICLE 2 : SERVICES FOURNIS</h4>
              <p>Mon ecoffre est un coffre-fort électronique permettant à l’Utilisateur de recevoir et conserver ses
                documents professionnels émis par son employeur et notamment ses bulletins de paie dans un espace
                hautement sécurisé. Mon ecoffre est une plateforme accessible par l’Utilisateur depuis n’importe
                quel ordinateur, smartphone ou tablette connecté à Internet.
                <strong>A l’occasion de chaque dépôt effectué dans le coffre-fort par un émetteur, l’Utilisateur peut
                  être averti par e-mail s’il coche la case correspondante. L’Utilisateur est informé que lors de son
                  inscription au Service, cette case est pré-cochée.</strong></p>
              <p><strong>L’Utilisateur est informé que les documents déposés par&nbsp;son employeur sont conservés pendant
                la durée contractuellement prévue avec l’employeur, à l’exception des bulletins de paie électronique
                qui seront conservés pendant une durée de cinquante (50) ans à compter de leurs dates de dépôt
                respectives dans le coffre-fort électronique sauf si l’Utilisateur demande la clôture de son compte
                et dans ce cas, il lui appartient de les télécharger avant leur suppression définitive par FIDUCIAL
                Cloud. L’Utilisateur devra également assurer leur conservation.</strong></p>
              <p><strong>Les bulletins de paie électroniques ne peuvent en aucun cas être supprimés directement par
                l’Utilisateur. </strong></p>

              <p><strong>Accessibilité</strong></p>
              <p>Mon ecoffre est accessible uniquement via le Site.
                Avant toute utilisation du coffre-fort électronique, l’Utilisateur reconnaît&nbsp;:
              </p><ul>
              <li>
                s’être assuré que le navigateur utilisé permet un accès sécurisé au Service&nbsp;;
              </li>
              <li>
                disposer d’une adresse e-mail valide.
              </li>
            </ul>
              <p>La plateforme Mon ecoffre est disponible 7 jours sur 7 et 24 heures sur 24 sauf périodes de
                maintenance, mises à jour, amélioration technique ou esthétique, interruption inopinée du Site,
                défaillance du réseau ou des intermédiaires techniques, difficultés informatiques ou techniques, ou
                tout cas fortuit ou évènement de force majeure.</p>

              <p><strong>Inscription</strong></p>
              <p>L’ouverture d’un coffre-fort électronique nécessite un identifiant et un mot de passe temporaire
                fourni par l’employeur.</p>
              <p>L’Utilisateur garantit que les informations qu’il transmet sont exactes et s’engage à les mettre à
                jour le cas échéant.</p>
              <p><strong>Il appartient à l’Utilisateur de vérifier régulièrement ses coordonnées et de les mettre à
                jour au fur et à mesure des éventuels changements. En l’absence d’adresse email valide, FIDUCIAL
                Cloud sera dans l’impossibilité de respecter ses obligations et ne pourra pas être tenu pour
                responsable des éventuelles conséquences qui en résulteraient.</strong></p>
              <p><strong>L’identifiant et le mot de passe de l’Utilisateur lui sont strictement personnels. Il
                s’oblige donc à les garder confidentiels. En cas de violation de la confidentialité de son
                identifiant et/ou mot de passe, l’Utilisateur devra avertir FIDUCIAL Cloud dans les plus brefs
                délais afin de garantir la sécurité du Compte.</strong></p>
              <p>L’Utilisateur pourra renseigner les coordonnées d’un tiers de confiance. Ce tiers de confiance pourra
                accéder au Compte uniquement si l’Utilisateur est dans l’incapacité de le faire (pour cause de
                maladie, de décès etc.). Le tiers de confiance n’aura accès au Compte qu’à titre exceptionnel et
                uniquement après que FIDUCIAL Cloud l’ai contacté à cette fin.</p>
              <p>L’Utilisateur doit veiller à ce que les coordonnées du tiers de confiance soient à jour et à les
                modifier le cas échéant.</p>
            </div>
            <div>
              <h4>ARTICLE 3 : RESPONSABILITES ET GARANTIES</h4>
              <br>
              <p><strong>Responsabilité pour la fourniture des Services et accessibilité au Site</strong></p>
              <p>FIDUCIAL Cloud, en qualité d’éditeur et d’hébergeur du Site, n’est tenu que d'une obligation de
                moyens en ce qui concerne le fonctionnement et la continuité du Site.
                FIDUCIAL Cloud ne peut en aucun cas être tenu responsable d’un quelconque dommage survenu à
                l’occasion de l’utilisation du Site. FIDUCIAL Cloud ne serait pas tenu pour responsable :</p>
              <ul>
                <li>
                  des vitesses d'accès au Site, des vitesses d'ouverture et de consultation des pages du Site,
                  de ralentissements externes, de la suspension ou de l'inaccessibilité du Site,
                </li>
                <li>
                  des faits dus à un cas de force majeure tel que défini par l’article 1218 du Code Civil ou
                  faits des tiers et notamment des pannes et des problèmes d'ordre technique concernant le
                  matériel, des programmes, des logiciels, de réseau Internet pouvant le cas échéant, entraîner la
                  suspension ou la cessation des Services,
                </li>
                <li>
                  si le Site s'avère incompatible ou présente des dysfonctionnements avec certains logiciels,
                  configurations, systèmes d'exploitation ou équipements que l’Utilisateur utilise,
                </li>
                <li>
                  des interruptions de services liées aux aléas du réseau Internet et/ou aux opérations de
                  maintenance.
                </li>
              </ul>
              <p>L’Utilisateur est seul responsable de l'utilisation du Site.</p>
              <p><strong>Les documents contenus dans le coffre-fort électronique de l’Utilisateur sont strictement
                confidentiels et restent à l’usage unique de ce dernier.</strong></p>
              <p>FIDUCIAL n’est en aucun cas responsable du contenu du coffre-fort numérique. Pour toute réclamation
                en lien avec le contenu d’un document déposé ou l’absence de dépôt d’un document, l’Utilisateur
                devra se rapprocher de son employeur.</p>
            </div>
            <div>
              <h4>ARTICLE 4&nbsp;: RESILIATION – CLÔTURE DU COMPTE – CLÔTURE DU SERVICE</h4>
              <p>FIDUCIAL Cloud peut être contraint de résilier le contrat le liant avec l’employeur ou clore le
                Compte ou le Service pour l’une des raisons suivantes&nbsp;:</p>
              <ul>
                <li>
                  La suspicion d’usurpation de l’identité de l’Utilisateur ou de l’employeur
                </li>
                <li>
                  L’existence de manquements aux présentes CGU
                </li>
                <li>
                  Le non-respect par l’employeur ou l’Utilisateur de la réglementation en vigueur
                </li>
                <li>
                  Sur demande d’une autorité compétente ou d’une décision de justice devenue définitive
                </li>
                <li>
                  La cessation de l’activité par FIDUCIAL Cloud
                </li>
              </ul>
              <p>Dans ce cas, FIDUCIAL Cloud informera l’Utilisateur par e-mail, sur l’adresse e-mail renseignée, et
                indiquera les causes de la résiliation ou de la clôture du Compte ou du Service. Il indiquera
                également le délai dont dispose l’Utilisateur pour procéder au téléchargement de ses documents ainsi
                que la date de fermeture définitive des accès.</p>
              <p>L’Utilisateur dispose également de la possibilité de supprimer son Compte. Cette possibilité n’est
                ouverte que si l’employeur a cessé de déposer les bulletins de paie électroniques dans le Compte.
                Cela doit faire l’objet d’une demande de l’Utilisateur directement auprès de son employeur.</p>
              <p>L’Utilisateur est averti que s’il décide de supprimer son Compte, l’ensemble des documents contenus dans
                son coffre-fort seront supprimés sans aucune possibilité de restauration. Il devra donc faire son
                affaire de procéder au téléchargement de ces documents avant de demander la suppression de son
                compte à FIDUCIAL Cloud.</p>
            </div>
            <div>
              <h4>ARTICLE 5&nbsp;: PROPRIETE INTELLECTUELLE</h4>
              <p>Le Site et notamment son interface graphique, ses éléments textuels et visuels dont les marques et
                les logos qui y figurent, sont protégés par des droits de propriété intellectuelle dont est
                titulaire FIDUCIAL Cloud ou que FIDUCIAL Cloud est autorisé à utiliser.</p>
              <p>Toute reproduction, imitation, représentation, modification, adaptation, traduction, extraction, par
                quelque procédé que ce soit et sur quelque support que ce soit, de tout ou partie du Site et
                notamment de son interface graphique, ses éléments textuels et visuels, sans avoir obtenu
                l’autorisation préalable écrite de FIDUCIAL, est susceptible de constituer une contrefaçon au sens
                des articles L335-2 et suivants du Code de la Propriété Intellectuelle et est strictement interdite.
                La reproduction ou la représentation du Site et de ses éléments est autorisée uniquement à des fins
                de consultation et de téléchargement des documents mis à la disposition de l’Utilisateur.</p>
              <p>Toute reproduction, imitation, utilisation ou apposition de l’une quelconque des marques reproduites
                sur le Site, sans avoir obtenu l’autorisation préalable écrite de FIDUCIAL Cloud, est susceptible de
                constituer une contrefaçon au sens des articles L713-2 et suivants et L717-1 et suivants du Code de
                la propriété intellectuelle et est strictement interdite.</p>
              <p>FIDUCIAL Cloud et/ou le titulaire des droits de propriété intellectuelle portant sur l’un quelconque
                des éléments du Site se réservent le droit d’engager des poursuites judiciaires à l’encontre de
                toute personne qui ne respecterait pas les interdictions précitées.
                La création de liens hypertextes, profonds ou non, vers le Site est strictement interdite, sauf avec
                l’accord écrit préalable de FIDUCIAL Cloud.</p>
            </div>
            <div>
              <h4>ARTICLE 6&nbsp;: DONNEES A CARACTÈRE PERSONNEL</h4>
              <p>La politique de FIDUCIAL Cloud en matière de confidentialité est consultable en
                <router-link to="/politique-de-confidentialite">cliquant ici</router-link>.</p>
            </div>
            <div>
              <h4>ARTICLE 7&nbsp;: COOKIES</h4>
              <p>FIDUCIAL Cloud utilise uniquement des cookies nécessaires au fonctionnement du Site. Ces cookies
                permettent à l’Utilisateur d’accéder à son Compte grâce à ses identifiants et mot de passe et de mettre
                en œuvre des mesures de sécurité pour permettre l’accès au Site. Ces cookies sont indispensables au
                fonctionnement du Site.</p>
            </div>
            <div>
              <h4>ARTICLE 8&nbsp;: DISPOSITIONS DIVERSES</h4>
              <p>Les présentes GGU sont rédigées en langue française uniquement.</p>
              <p><strong>Loi applicable</strong> – Les présentes CGU sont soumises au droit français.</p>
            </div>
          </v-card-text>

          <v-card-text v-if="getLocale() === 'en'">
            A TRADUIRE
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button type="button" @click="closeCGUDialog()" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content">Accepter</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'

export default {
  name: 'CGUDialog',
  data: () => ({
    successMessage: null,
    errors: []
  }),
  props: {
    cguDialogState: Boolean
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    putUser () {
      this.successMessage = null
      this.errors = []

      const data = {
        acceptedCGU: true
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/users/' + this.user.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data.message
              this.errors = []
              this.$store.commit('setUser', data)
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response && response.status === 500) {
            this.errors.push({
              title: 'Une erreur est intervenue lors de la modification de l\'utilisateur.'
            })
          }
        })
    },
    closeCGUDialog () {
      this.putUser()

      this.$emit('closeCGUDialog', false)
    },
    getLocale () {
      return localStorage.getItem('locale') || 'fr'
    }
  }
}
</script>
