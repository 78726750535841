<template>
  <v-footer>
    <div>© FIDUCIAL Cloud 2021-{{ year }} - {{ $t('message.all_rights_reserved') }}</div>
    <div v-if="!hasVaultProfile('USER', currentVault)">
      <router-link to="/mentions-legales">
        <span>{{ $t('message.legal_notices') }}</span>
      </router-link> |
      <router-link to="/cgu">
        <span>{{ $t('message.cgu') }}</span>
      </router-link>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    currentVault () {
      return this.$store.getters.getCurrentVault
    }
  },
  methods: {
    hasVaultProfile (profile) {
      if (!this.currentVault) return false
      if (!this.currentVault.vaultsRole) return false
      const vaultsRole = this.currentVault.vaultsRole
      for (let i = 0; i < vaultsRole.length; i++) {
        if (vaultsRole[i].profiles.includes(profile)) {
          return true
        }
      }

      return false
    }
  }
}
</script>
