export default {
  title: 'Mon ecoffre',
  apiUrl: 'https://back.mon-ecoffre.com/api',
  socketUrl: 'https://back.mon-ecoffre.com/socket.io',
  cff2faUrl: 'https://back.mon-ecoffre.com/2fa_check',
  cffAutUrl: 'https://back.mon-ecoffre.com/token/auth',
  cffCasUrl: 'https://back.mon-ecoffre.com/cas/login',
  casCallbackUrl: 'https://mon-ecoffre.com/connexion-code-budget-callback',
  casUrl: 'https://applnet.fiducial.fr',
  autUrl: 'https://auth.fiducial.fr/oauth2/authorize',
  logoutUrl: 'https://moncompte.fiducial.fr/logout',
  accountUrl: 'https://moncompte.fiducial.fr/mon-compte/information',
  upgradeOptionsUrl: 'https://moncompte.fiducial.fr/mon-compte/abonnements',
  collaboratorUrl: 'https://moncompte.fiducial.fr/mon-compte/collaborateurs',
  acrValues: 'loa-4',
  grantType: 'implicit',
  clientId: 'fEvyg6t6TaNpHxXBEtxFXZSUbG9KquxH',
  redirectUri: 'https://mon-ecoffre.com/callback',
  responseType: 'id_token token',
  nonce: 'n875-0S6_WzA2Mjp',
  scope: 'rcu+profile+openid+refcli'
}
