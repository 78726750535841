import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import * as io from 'socket.io-client'
import VueSocketIOExt from 'vue-socket.io-extended'
import config from './config'
import VueYouTubeEmbed from 'vue-youtube-embed'
import IdleVue from 'idle-vue'
import VueI18n from 'vue-i18n'
import translationsEn from './translations/translations.en.js'
import translationsFr from './translations/translations.fr.js'
import CountryFlag from 'vue-country-flag'

import './plugins/filters/date'
import './plugins/filters/utils'
import 'vue2-datepicker/locale/fr'

require('@/assets/scss/app.scss')
require('@/assets/css/icons.css')
require('@/assets/js/global/textfield')
require('@/assets/js/lib/bootstrap/bootstrap.bundle.min')

Vue.component('country-flag', CountryFlag)

Vue.config.productionTip = false
Vue.prototype.$http = axios

const socket = io(config.socketUrl)

Vue.use(VueSocketIOExt, socket, { store })
Vue.use(VueYouTubeEmbed)

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 30000,
  startAtIdle: false
})

Vue.use(VueI18n)

const messages = {
  en: translationsEn,
  fr: translationsFr
}

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'fr',
  fallbackLocale: 'fr',
  messages
})

Vue.filter('translate', key => {
  return Vue.prototype.$t(key)
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  beforeCreate () {
    this.$store.commit('initStore')
    this.$store.dispatch('socketLoaded', this.$store.getters.getUser)
  },
  render: h => h(App)
}).$mount('#app')
