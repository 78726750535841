import Vue from 'vue'
import config from '@/config'

const getDefaultState = () => {
  return {
    title: [],
    content: [],
    publishDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
    userType: [],
    list: [],
    isLoginPage: false,
    lastReleaseLogSawId: null
  }
}

const releaselog = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getPublishDate: state => {
      const d = new Date(state.publishDate)

      let month = String(d.getMonth() + 1)
      let day = String(d.getDate())
      const year = String(d.getFullYear())
      const hour = String(d.getHours())
      const minutes = String(d.getMinutes())
      const seconds = String(d.getSeconds())

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
    }
  },
  mutations: {
    updateLastReleaseLogSawId: (state, value) => {
      state.lastReleaseLogSawId = value
    },
    updateTitle: (state, value) => {
      state.title = value
    },
    updateContent: (state, value) => {
      state.content = value
    },
    updatePublishDate: (state, value) => {
      const date = value.split('-')
      const current = state.publishDate.split(' ')

      state.publishDate = `${date[0]}-${date[1]}-${date[2]} ${current[1]}`
    },
    updatePublishHour: (state, value) => {
      const time = value.split(':')
      const current = state.publishDate.split(' ')
      state.publishDate = `${current[0]} ${time[0]}:${time[1]}:${time[2]}`
    },
    updateList: (state, value) => {
      state.list = value
    },
    updateUserType: (state, value) => {
      state.userType = value
    },
    resetState: (state) => {
      Object.assign(state, getDefaultState())
    },
    toggleIsLoginPage: (state) => {
      state.isLoginPage = !state.isLoginPage
    },
    removeItem: (state, index) => {
      state.list.splice(index, 1)
    }
  },
  actions: {
    getData ({ commit, dispatch, rootGetters }, { uri = null, user = null }) {
      commit('resetState')
      let slug = config.apiUrl + '/releaselogs'

      if (uri) slug += '/' + uri
      if (user) {
        slug += '?user=' + user
        commit('toggleIsLoginPage')
      }

      Vue.prototype.$http
        .get(slug, {
          headers: {
            Authorization: 'Bearer ' + rootGetters.getToken
          }
        })
        .then(response => {
          if (Array.isArray(response.data) && response.data.length === 0) {
            this.loading = false
            return
          }
          if (Array.isArray(response.data) && response.data.length > 0) {
            commit('updateLastReleaseLogSawId', response.data[0].id)
            commit('updateList', response.data)
          } else {
            commit('updateLastReleaseLogSawId', response.data.id)
            commit('updateTitle', response.data.translations)
            commit('updateContent', response.data.content)
            commit('updateUserType', response.data.userType)

            const datetime = response.data.publishDate.split('T')
            commit('updatePublishDate', datetime[0])
            commit('updatePublishHour', datetime[1])
            this.loading = false
          }
        })
        .catch(e => {
          const response = e.response
          console.log(response)
          this.loading = false

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        })
    }
  }
}

export default releaselog
