const getDefaultState = () => {
  return {
    lastId: 0,
    messages: []
  }
}

const messages = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    addId (state) {
      state.lastId++
    },
    addMessage (state, message) {
      state.messages.push(message)
    },
    unshiftMessage (state, message) {
      state.messages.unshift(message)
    },
    removeMessage (state, id) {
      const indexToDelete = state.messages.findIndex(n => n.id === id)
      if (indexToDelete !== -1) {
        state.messages.splice(indexToDelete, 1)
      }
    },
    resetState: function (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    // time = -1 to keep open
    addMessage ({ commit, state }, { text, type = 'danger', time = 5000 }) {
      commit('addId')
      const message = {
        text: text,
        type: type,
        time: time,
        id: state.lastId
      }
      commit('unshiftMessage', message)
    },
    removeMessage ({ commit }, id) {
      commit('removeMessage', id)
    }
  }
}

export default messages
