<template>
  <div class="box-login">
    <div class="box">
      <div class="box-panel">
        <div class="box-header">
          <div class="logo">
            <img v-if="!$route.meta.budgetCode" src="../assets/images/logo-Mon-Ecoffre-full-blue.svg" alt="Logo Mon ecoffre">
            <img v-else src="../assets/images/logo-budgetCode-full.svg" alt="Logo Mon ecoffre">
          </div>
        </div>
        <div class="box-content">
          <slot></slot>
        </div>
      </div>
    </div>
    <footer v-if="$route.meta.budgetCode" class="justify-center">
    </footer>
    <footer v-else>
      <router-link to="/faq">{{ $t('message.faq') }}</router-link>
      <span>|</span>
      <router-link to="/mentions-legales">{{ $t('message.legal_notices') }}</router-link>
      <span v-if="user">|</span>
      <router-link  v-if="user" to="/cgu">{{ $t('message.cgu') }}</router-link>
      <!--for available langues create router link-->
    </footer>
    <div class="animated-background-area" :class="($route.meta.budgetCode) ? 'budget-code' : ''">
      <ul class="vaults">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
  created () {
    if (window.location.hostname.includes('fiducial.dom') && !this.$store.getters.isAuthenticated) {
      this.$router.push({ name: 'LoginBudget' })
    }

    const body = document.querySelector('body')

    body.classList.add('page-box-form')
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    }
  },
}
</script>

<style>
.animated-background-area.budget-code {
  background-image: linear-gradient(180deg, #30bac5 10%, #331d72 100%);
}
</style>
