import Vue from 'vue'
import config from '@/config'

const getDefaultState = () => {
  return {
    list: [],
    UserVaultDetails: []
  }
}

const usersVault = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    addVaultUsers: (state, vaultUsers) => {
      state.list.push(...vaultUsers)
    },
    setVaultUsers: (state, vaultUsers) => {
      state.list = vaultUsers
    },
    setUserVaultDetails: (state, list) => {
      state.UserVaultDetails = list
    },
    resetState: function (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async getData ({ commit, dispatch, rootGetters }, params = null) {
      commit('resetState')
      await Vue.prototype.$http
        .get(config.apiUrl + '/vault-users', {
          headers: {
            Authorization: 'Bearer ' + rootGetters.getToken
          },
          params: params
        })
        .then(response => {
          commit('setVaultUsers', JSON.parse(response.data.data))
          return response
        })
        .catch(e => {
          const response = e.response

          if (response.status === 500) {
            dispatch('messages/addMessage', { text: 'Erreur' }, { root: true })
          }

          this.loading = false
          return e.response
        })
        .then((event) => {
          if (event.data.notify) {
            JSON.parse(event.data.notify).forEach(e => {
              dispatch('messages/addMessage', { text: e.text, type: e.type }, { root: true })
            })
          }
        })
    },
    async getUser ({ commit, dispatch, rootGetters }, id) {
      await Vue.prototype.$http
        .get(config.apiUrl + '/vault-user/' + id, {
          headers: {
            Authorization: 'Bearer ' + rootGetters.getToken
          }
        })
        .then(response => {
          commit('setUserVaultDetails', JSON.parse(response.data.data))
          return response
        })
        .catch(e => {
          const response = e.response

          if (response.status === 500) {
            dispatch('messages/addMessage', { text: 'Erreur' }, { root: true })
          }

          this.loading = false
          return e.response
        })
        .then((event) => {
          if (event.data.notify) {
            JSON.parse(event.data.notify).forEach(e => {
              dispatch('messages/addMessage', { text: e.text, type: e.type }, { root: true })
            })
          }
        })
    }
  }
}

export default usersVault
