<template>
    <v-dialog
      v-model="isDialogActive"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.release_logs') }}</span>
        </v-card-title>
        <v-container v-for="(row, index) in $store.state.releaselog.list" :key="index">
          <v-card outlined tile>
            <v-card-title>
              <h5>{{ getTitleByLocale(row) }}</h5>
            </v-card-title>
            <v-card-subtitle>
              <small>{{row.publishDate | dateFormat}}</small>
            </v-card-subtitle>
            <v-card-text v-html="getContentByLocale(row)"></v-card-text>
          </v-card>
        </v-container>
        <v-card-actions>
          <v-col class="text-left">
            <v-checkbox
              :label="$t('message.dont_show_again')"
              v-model="checkbox"
              required
            ></v-checkbox>
            <input type="hidden" v-model="releaseLogSawId">
          </v-col>
          <v-col class="text-right">
            <button type="button" @click="closeReleaselogDialog()" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
              <span class="v-btn__content">{{ $t('message.close') }}</span>
            </button>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

import Vue from "vue";
import config from "../../../config";

export default {
  name: 'ReleaselogDialog',
  data () {
    return {
      checkbox: false,
    }
  },
  computed: {
    isDialogActive: {
      get: function () {
        return (this.$store.state.releaselog.isLoginPage && this.$store.state.releaselog.list.length)
      },
      set: function (value) {}
    },
    releaseLogSawId: {
      get: function () {
        return this.$store.state.releaselog.lastReleaseLogSawId
      },
      set: function (value) {
        this.$store.commit('releaselog/updateLastReleaseLogSawId', value)
      }
    },
    user () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    putUser () {
      this.successMessage = null
      this.errors = []

      const data = {
        lastReleaseSaw: this.releaseLogSawId
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/users/' + this.user.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'Content-Type': 'multipart/form-data'
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data.message
              this.errors = []
              this.$store.commit('setUser', data)
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response && response.status === 400) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          if (response && response.status === 500) {
            this.errors.push({
              title: this.$t('message.user_update_error')
            })
          }
        })
    },
    closeReleaselogDialog () {
      this.$store.commit('releaselog/toggleIsLoginPage');

      if (this.checkbox === true) {
        this.putUser()
      }

      this.$emit('closeReleaselogDialog', false)
    },
    getTitleByLocale (row) {
      const locale = localStorage.getItem('locale') || 'fr'

      if (row.translations && row.translations[locale]) {
        return row.translations[locale].title
      }

      return ''
    },
    getContentByLocale (row) {
      const locale = localStorage.getItem('locale') || 'fr'

      if (row.translations && row.translations[locale]) {
        return row.translations[locale].content
      }

      return ''
    }
  }
}
</script>
