<template>
  <div>
    <Notifications/>
    <MenuLeft></MenuLeft>
    <MenuTopRcu v-if="authenticated && user.rcu && !isCasUser"></MenuTopRcu>
    <MenuTop v-else></MenuTop>
    <main>
      <ReleaselogDialog></ReleaselogDialog>
      <div class="bg-gray-mobile" @click="collapse"></div>
      <slot></slot>
      <DocumentsInProgress></DocumentsInProgress>
      <Footer></Footer>
    </main>
    <VideoDialog
      :key="Date.now() + 10"
      :video-dialog-state="videoDialogState"
      @closeVideoDialog="closeVideoDialog($event)"
    ></VideoDialog>
    <CGUDialog
      :key="Date.now() + 12"
      :cgu-dialog-state="cguDialogState"
      @closeCGUDialog="closeCGUDialog($event)"
    ></CGUDialog>
    <IdleDialog
      v-if="authenticated"
      :idle-dialog-state="idleDialogState"
      :key="Date.now() + 13"
      @closeIdleDialog="closeIdleDialog($event)"
    ></IdleDialog>
  </div>
</template>

<script>
import Notifications from '@/components/Notifications.vue'
import MenuLeft from '@/components/MenuLeft.vue'
import MenuTop from '@/components/MenuTop.vue'
import MenuTopRcu from '@/components/MenuTopRcu.vue'
import Footer from '@/components/Footer.vue'
import DocumentsInProgress from '../components/DocumentsInProgress'
import VideoDialog from '../components/Dialog/Video/VideoDialog'
import CGUDialog from '../components/Dialog/CGU/CGUDialog'
import IdleDialog from '../components/Dialog/Idle/IdleDialog'
import ReleaselogDialog from '../components/Dialog/Releaselog/ReleaselogDialog'

export default {
  name: 'DefaultLayout',
  data: () => ({
    videoDialogState: false,
    cguDialogState: false,
    idleDialogState: false,
    refreshTime: 540
  }),
  created () {
    if (window.location.hostname.includes('fiducial.dom') && !this.$store.getters.isAuthenticated) {
      this.$router.push({ name: 'LoginBudget' })
    }

    window.addEventListener(
      'resize',
      this.onResize
    )

    const body = document.querySelector('body')

    body.classList.remove('page-box-form')
  },
  mounted () {
    this.resolveCGUState()
    this.resolveVideoState()

    this.refreshTimer = setInterval(() => {
      const lastRefresh = this.$store.getters.getLastRefresh
      const now = Date.now()

      if (this.user && lastRefresh && ((now - lastRefresh) / 1000 > this.refreshTime) && this.idleDialogState === false) {
        if (this.isIdle) {
          this.idleDialogState = true
        } else {
          this.$store.dispatch('refreshToken')
        }
      }
    }, 1000)
  },
  computed: {
    authenticated () {
      return this.$store.getters.isAuthenticated
    },
    user () {
      return this.$store.getters.getUser
    },
    isIdle () {
      return this.$store.state.idleVue.isIdle
    },
    isCasUser () {
      return this.$store.getters.isCasUser
    }
  },
  methods: {
    onResize () {
      this.$store.commit('setWindowWidth')
    },
    collapse () {
      const sidebar = document.querySelector('#sidebar')

      sidebar.classList.toggle('stretched')
    },
    resolveVideoState () {
      this.videoDialogState = this.user && this.user.rcu && !this.user.watchedVideo && this.user.acceptedCGU && !this.isCasUser
    },
    closeVideoDialog () {
      this.videoDialogState = false
    },
    resolveCGUState () {
      this.cguDialogState = this.user && !this.user.acceptedCGU
    },
    closeCGUDialog () {
      this.cguDialogState = false

      setTimeout(() => {
        if (this.user.rcu && !this.user.watchedVideo) {
          this.videoDialogState = true
        }
      }, 1000)
    },
    closeIdleDialog () {
      this.idleDialogState = false
    }
  },
  beforeDestroy () {
    clearInterval(this.refreshTimer)
  },
  components: {
    MenuLeft,
    MenuTop,
    MenuTopRcu,
    Footer,
    DocumentsInProgress,
    VideoDialog,
    CGUDialog,
    IdleDialog,
    ReleaselogDialog,
    Notifications
  }
}
</script>
