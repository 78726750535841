import config from '@/config'
import axios from "axios"

const getDefaultState = () => {
  return {
    errors: [],
    loading: false,
    data: null,
    charts: []
  }
}

const statistics = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateData: (state, value) => {
      state.data = value
    },
    setCharts: (state, value) => {
      state.charts = value
    },
    setLoading: (state, value) => {
      state.loading = value
    }
  },
  actions: {
    getCharts ({ commit, dispatch, rootGetters }, params) {
      commit('setLoading', true)

      const options = {
        headers: {
          Authorization: 'Bearer ' + rootGetters.getToken
        },
        params: params
      }

      axios
        .get(config.apiUrl + '/statistics/all', options)
        .then(response => {
          commit('setCharts', response.data)
          commit('setLoading', false)
        })
        .catch(e => {
          const response = e.response
          commit('setLoading', false)

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        })
    },
    getData ({ commit, dispatch, rootGetters }, params) {
      commit('setLoading', true)

      const options = {
        headers: {
          Authorization: 'Bearer ' + rootGetters.getToken
        },
        params: params
      }

      axios
        .get(config.apiUrl + '/statistics', options)
        .then(response => {
          commit('updateData', response.data)
          commit('setLoading', false)
        })
        .catch(e => {
          const response = e.response
          commit('setLoading', false)

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        })
    }
  }
}

export default statistics
