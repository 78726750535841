import Vue from 'vue';
import config from '@/config';
import axios from "axios";
import slugify from "slugify";

const getDefaultState = () => {
  return {
    releaselogs: [],
    documents: [],
    user: null,
    loading: true,
    count: 0
  }
}

const dashboardStore = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateUser: (state, value) => {
      state.user = value
    },

    updateDocuments: (state, value) => {
      state.documents = value
    },

    updateReleaselogs: (state, value) => {
      state.releaselogs = value
    },

    resetState: function (state) {
      Object.assign(state, getDefaultState())
    },

    setLoading: (state, value) => {
      state.loading = value;
    }
  },
  actions: {
    getDocuments ({ commit, dispatch, rootGetters, state }, { from, to }) {
      commit('updateDocuments', []);

      let options = {
        headers: {
          Authorization: 'Bearer ' + rootGetters.getToken
        },
        params: {
          vault: rootGetters.getCurrentVault.id,
          from: from,
          to: to,
          isExternal: 1
        }
      }

      axios
        .get(config.apiUrl + '/documents', options)
        .then(response => {
          let currentFolders = JSON.parse(JSON.stringify(rootGetters.getCurrentRootFolders));

          if (currentFolders instanceof Array && currentFolders.length > 0) {
            let temp = response.data.map(document => {
              rootGetters.getCurrentRootFolders.forEach(folder => {
                if (document.sectionId === folder.section.id) {
                  document.folderUrl = "/mes-documents/" + slugify(folder.section.name, { lower: true });
                  if (document.folderId) {
                    document.folderUrl += '?folder=' + document.folderId;
                  }
                }
              });
              return document;
            });
            commit('updateDocuments', temp)
          }

          commit('setLoading', false)
        })
        .catch(e => {
          const response = e.response

          this.loading = false

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        })
    },
    getReleaselogs ({ commit, rootGetters }) {
      commit('updateReleaselogs', []);

      let options = {
        headers: {
          Authorization: 'Bearer ' + rootGetters.getToken
        },
        params: {
          user: rootGetters.getUser.id
        }
      }

      axios
        .get(config.apiUrl + '/releaselogs', options)
        .then(response => {
          if (Array.isArray(response.data)) {
            commit('updateReleaselogs', response.data);
          } else {
            commit('updateReleaselogs', [response.data]);
          }
        })
        .catch(e => {
          const response = e.response

          this.loading = false

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        })
    }
  },
}

export default dashboardStore
