import config from '@/config';
import axios from "axios";

const getDefaultState = () => {
  return {
    loading: true,
    maxUsage: 0,
    trashUsage: 0,
    currentUsage: 0,
    documentUsage: 0,
    gigaBytes: 1000000000,
    megaBytes: 1000000,
    isMegaBytes: true,
    numberFormat: 2
  }
}

const storageStore = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState: function (state) {
      Object.assign(state, getDefaultState())
    },

    updateMaxUsage: (state, value) => {
      state.maxUsage = value
    },

    updateTrashUsage: (state, value) => {
      state.trashUsage = value
    },

    updateCurrentUsage: (state, value) => {
      state.currentUsage = value;
    },

    updateDocumentUsage: (state, value) => {
      state.documentUsage = value;
    },

    setLoading: (state, value) => {
      state.loading = value;
    },

    updateIsMegabytes: (state, value) => {
      state.isMegaBytes = value;
    },
    updateNumberFormat: (state, value) => {
      state.numberFormat = value;
    }
  },
  getters: {
    calculatePercentage (state) {
      if (state.currentUsage) {
        let percentage = (( state.currentUsage * 100) / state.maxUsage).toFixed(1)
        return (percentage + '%').replace('.', ',');
      }
      return '---';
    }
  },
  actions: {
    getPersonalStorageStatus ({ commit, dispatch, rootGetters }) {
      commit('resetState');

      let options = {
        headers: {
          Authorization: 'Bearer ' + rootGetters.getToken
        },
        params: {
          vault: rootGetters.getCurrentVault.id,
        }
      }

      axios
        .get(config.apiUrl + '/section_usages', options)
        .then(response => {
          const documentUsage = response.data.currentUsage - response.data.trashUsage;
          commit('updateMaxUsage', response.data.maxUsage)
          commit('updateTrashUsage', response.data.trashUsage)
          commit('updateCurrentUsage', response.data.currentUsage)
          commit('updateDocumentUsage', documentUsage)
          commit('updateIsMegabytes', response.data.maxUsage < 1000000000 ?  true : false)
          commit('updateNumberFormat', response.data.maxUsage < 1000000000 ?  2 : 3)
          commit('setLoading', false)
        })
        .catch(e => {
          const response = e.response
          if (response.status === 500) {
            commit('resetState');
          }

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', {root: true})
          }
        })
    }
  }
}

export default storageStore
