<template>
  <v-row justify="center">
    <v-dialog
      v-model="idleDialogState"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('message.your_session_will_expire') }}</span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <div>
              <p>{{ $t('message.you_will_be_automatically_logged_out_in') }} {{ this.time }} {{ this.time > 1 ? $t('message.seconds') : $t('message.second') }}</p>
            </div>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button type="button" @click="closeIdleDialog()" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content">{{ $t('message.extend_my_session') }}</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'IdleDialog',
  data: () => ({
    time: 30,
    successMessage: null,
    errors: []
  }),
  props: {
    idleDialogState: Boolean
  },
  mounted () {
    if (this.user && this.idleDialogState === true) {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--
        } else {
          this.$emit('closeIdleDialog', false)
          clearInterval(this.timer)
          this.time = 30
          this.$store.dispatch('logout', 'timeout')
        }
      }, 1000)
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    closeIdleDialog () {
      this.$store.dispatch('refreshToken')
      clearInterval(this.timer)
      this.time = 30
      this.$emit('closeIdleDialog', false)
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
