import Vue from 'vue'
import config from '@/config'

const getDefaultState = () => {
  return {
    title: '',
    content: '',
    slug: '',
    list: []
  }
}

const page = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateTitle: (state, value) => {
      state.title = value
    },
    updateContent: (state, value) => {
      state.content = value
    },
    updateSlug: (state, value) => {
      state.slug = value
    },
    updateList: (state, value) => {
      state.list = value
    },
    resetState: function (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    getData ({ commit, dispatch, rootGetters }, uri = null) {
      commit('resetState')
      const slug = (uri) || ''
      Vue.prototype.$http
        .get(config.apiUrl + '/pages/' + slug, {
          headers: {
            Authorization: 'Bearer ' + rootGetters.getToken
          }
        })
        .then(response => {
          if (Array.isArray(response.data)) {
            commit('updateList', response.data)
          } else {
            const locale = localStorage.getItem('locale') || 'fr'

            commit('updateTitle', response.data.translations[locale].title)
            commit('updateContent', response.data.translations[locale].content)
            this.loading = false
          }
        })
        .catch(e => {
          const response = e.response

          this.loading = false

          if (response.status === 400 || response.status === 404) {
            this.hasMasterKey = false
          }

          if (response.status === 403) {
            dispatch('logout', 'rejected', { root: true })
          }
        })
    }
  }
}

export default page
