<template>
  <v-app id="inspire" :class="(this.$store.getters.isBudgetUser || $route.meta.budgetCode) ? 'budgetCode' : '' ">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view :layout.sync="layout"/>
    </component>
  </v-app>
</template>

<script>
import { budgetCodeTheme, primaryTheme } from './plugins/vuetify'

export default {
  name: 'App',
  data () {
    return {
      layout: 'div'
    }
  },
  methods: {
    isBudgetUser () {
      this.$router.onReady(() => {
        return (this.$store.getters.isBudgetUser || ['LoginBudget', 'ContactBudget'].includes(this.$route.name.toString()))
      })
    }
  },
  created () {
    this.$router.onReady(() => {
      if (this.$store.getters.isBudgetUser || ['LoginBudget', 'ContactBudget'].includes(this.$route.name.toString())) {
        document.querySelectorAll("[id*='dynamic_link']").forEach((e) => {
          e.href = e.href.replace('/favicon/', '/budget_code_favicon/')
        })
        setTimeout(() => { document.title = 'CCB – Coffre-fort Code Budget' }, 0)
        this.$vuetify.theme.themes.light = budgetCodeTheme
      } else {
        document.querySelectorAll("[id*='dynamic_link']").forEach((e) => {
          e.href = e.href.replace('/budget_code_favicon/', '/favicon/')
        })
        this.$vuetify.theme.themes.light = primaryTheme
      }
    })
  },
  watch: {
    '$store.getters.isBudgetUser': function (e) {
      if (e) {
        document.querySelectorAll("[id*='dynamic_link']").forEach((e) => {
          e.href = e.href.replace('/favicon/', '/budget_code_favicon/')
        })
        setTimeout(() => { document.title = 'CCB – Coffre-fort Code Budget' }, 0)
        this.$vuetify.theme.themes.light = budgetCodeTheme
      } else {
        document.querySelectorAll("[id*='dynamic_link']").forEach((e) => {
          e.href = e.href.replace('/budget_code_favicon/', '/favicon/')
        })
        this.$vuetify.theme.themes.light = primaryTheme
      }
    }
  }
}
</script>
