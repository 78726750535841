<template>
  <nav id="sidebar" :class="{ below: authenticated && user.rcu && !isCasUser }" class="navbar stretched">
    <router-link v-if="this.isBudgetUser" id="logo" :to="$router.resolveHome($store)" draggable="false" class="p-2 justify-content-center">
      <div>
        <img src="@/assets/images/logo-budgetCode-full.svg">
      </div>
    </router-link>
    <router-link v-else id="logo" :to="'/'" draggable="false">
      <div id="logo-icon">
        <img src="@/assets/images/logo-monecoffre-icon.png" draggable="false">
      </div>
      <div id="logo-text">
        <img src="@/assets/images/logo-monecoffre-text.png">
      </div>
    </router-link>
    <ul>
      <section class="pt-2 pb-2" v-if="
        authenticated &&
        hasProfile('VAULT') &&
        hasDocumentSectionAccess() &&
        currentRootfolders &&
        currentRootfolders.length &&
        !user.rcu &&
        !isCasUser"
      >
        <li>
          <router-link to="/" @click.native="collapse" draggable="false"><i class="mdi mdi-view-dashboard"></i><span>{{ $t('message.dashboard') }}</span></router-link>
        </li>
      </section>
      <section v-if="authenticated && hasProfile('ADMIN') || hasProfile('SUPPORT')">
         <h1>{{ $t('message.admin') }}</h1>
         <li v-if="hasRole('ROLE_LIST_USER')">
           <router-link to="/admin/utilisateurs" @click.native="collapse" draggable="false"><i class="fas fa-list"></i><span>{{ $t('message.employee_management') }}</span></router-link>
         </li>
        <li>
          <router-link to="/administration/page" @click.native="collapse" draggable="false"><i class="fa fa-list"></i><span>{{ $t('message.page_management') }}</span></router-link>
        </li>
        <li>
          <router-link to="/administration/gestion-des-nouveautes" @click.native="collapse" draggable="false">
            <i class="fas fa-list"></i><span>{{ $t('message.release_log_management') }}</span>
          </router-link>
        </li>
        <li v-if="hasRole('ROLE_MASSIVE_UPLOAD')">
          <router-link :to="{name: 'MassiveUploadList'}" @click.native="collapse" draggable="false"><i class="fas fa-list"></i><span>{{ $t('message.massive_upload') }}</span></router-link>
        </li>
        <li>
          <router-link to="/administration/gestion-des-statistiques" @click.native="collapse" draggable="false">
            <i class="fas fa-chart-bar"></i><span>{{ $t('message.statistics') }}</span>
          </router-link>
        </li>
      </section>

      <section v-if="authenticated && hasProfile('ADMIN_BUDGET')">
        <h1>Administration Code Budget</h1>
        <li v-if="hasRole('ROLE_LIST_USER_BUDGET')">
          <router-link to="/admin/utilisateurs-code-budget" @click.native="collapse" draggable="false"><i class="fas fa-list"></i><span>{{ $t('message.collaborators_management') }}</span></router-link>
        </li>
      </section>

      <section v-if="authenticated && hasProfile('MASTER_KEY')">
        <h1>{{ $t('message.master_key') }}</h1>
        <li v-if="hasRole('ROLE_SET_MASTER_KEY')">
          <router-link to="/admin/initialisation-cle-maitre" @click.native="collapse"><i class="fas fa-key"></i><span>{{ $t('message.master_key_init') }}</span></router-link>
        </li>
        <li v-if="hasRole('ROLE_UPDATE_MASTER_KEY')">
          <router-link to="/admin/modification-cle-maitre" @click.native="collapse"><i class="fas fa-pen"></i><span>{{ $t('message.master_key_update') }}</span></router-link>
        </li>
        <li v-if="hasRole('ROLE_VERIFY_MASTER_KEY')">
          <router-link to="/admin/verification-cle-maitre" @click.native="collapse"><i class="fas fa-check"></i><span>{{ $t('message.master_key_check') }}</span></router-link>
        </li>
      </section>

      <section v-if="authenticated && hasProfile('VAULT') && hasDocumentSectionAccess() && currentRootfolders && currentRootfolders.length">
        <h1 v-if="!isCasUser">{{ $t('message.my_documents') }}</h1>
        <h1 v-if="isCasUser">{{ $t('message.documents') }}</h1>

        <li v-for="(rootFolder, index) in currentRootfolders" :key="index">
          <router-link :to='"/mes-documents/" + slugify(rootFolder.name)' @click.native="collapse" draggable="false" v-if="rootFolder.section.type !== 'SECRET'">
            <i class="far fa-file-alt"></i><span>{{ $t('message.section_' + rootFolder.name) }}</span>
          </router-link>
        </li>
      </section>

      <section
        class="section-small"
        v-if="authenticated && hasProfile('VAULT') && hasSecretSectionAccess() && this.isBudgetUser"
      >
        <li>
          <router-link :to='"/mes-secrets"' @click.native="collapse" draggable="false">
            <i class="mdi mdi-key-variant"></i><span >{{ $t('message.my_secrets') }}</span>
          </router-link>
        </li>
      </section>

      <section
        class="section-small"
        v-if="authenticated && hasProfile('VAULT') && hasSecretSectionAccess() && currentRootfolders && currentRootfolders.length && !hasVaultProfile('USER', currentVault)"
      >
        <li>
          <router-link :to='"/mes-secrets" + (user.rcu ? "-entreprise" : "")' @click.native="collapse" draggable="false">
            <i class="mdi mdi-key-variant"></i><span v-if="!user.rcu">{{ $t('message.my_secrets') }}</span><span v-else>{{ $t('message.my_company_secrets') }}</span>
          </router-link>
        </li>
      </section>

      <section class="section-small" v-if="authenticated && hasProfile('VAULT') && !isCasUser && hasDocumentSectionAccess() && currentRootfolders && currentRootfolders.length">
        <li>
          <router-link :to='"/mes-documents/corbeille"' @click.native="collapse" draggable="false">
            <i class="far fa-trash-alt"></i><span>{{ $t('message.trash') }}</span>
          </router-link>
        </li>
      </section>

      <section class="section-small" v-if="authenticated">
        <h1>{{ $t('message.vault_management') }}</h1>
        <li v-if="hasVaultProfile('ADMIN', currentVault)">
          <router-link to="/administration/rights-management" @click.native="collapse" draggable="false"><i class="fas fa-user-friends"></i><span>{{ $t('message.collaborators_management') }}</span></router-link>
        </li>
        <li>
          <router-link to="/historique-des-actions" @click.native="collapse" draggable="false"><i class="fas fa-list"></i><span>{{ $t('message.logs') }}</span></router-link>
        </li>
      </section>

      <section v-if="authenticated && user.rcu && hasRole('ROLE_LEADER', currentCompany)">
        <h1>{{ $t('message.admin') }}</h1>
        <li>
          <router-link to="/administration/collaborateurs" @click.native="collapse" draggable="false"><i class="fas fa-user-friends"></i><span>{{ $t('message.collaborators_management') }}</span></router-link>
        </li>
      </section>

      <section>
        <h1>{{ $t('message.help') }}</h1>
        <li v-if="user && !user.rcu && !hasVaultProfile('USER', currentVault)">
          <router-link :to="'/p/nouveautes'" @click.native="collapse" draggable="false"><i class="fas fa-bullhorn"></i><span>{{ $t('message.release_logs') }}</span></router-link>
        </li>
        <li>
          <router-link to="/faq" @click.native="collapse" draggable="false"><i class="far fa-comments"></i><span>{{ $t('message.faq') }}</span></router-link>
        </li>
        <li v-if="isCasUser">
          <a href="/mode_emploi_Coffre_Code_Budget.pdf" target="_blank" draggable="false"><i class="far fa-clipboard"></i><span>{{ $t('message.user_guide') }}</span></a>
        </li>
        <li v-if="user && !user.rcu && !hasVaultProfile('USER', currentVault)">
          <router-link to="/contact" @click.native="collapse" draggable="false"><i class="fab fa-stack-exchange"></i><span>{{ $t('message.contact') }}</span></router-link>
        </li>
        <li v-if="isCasUser">
          <router-link to="/contact-budget" @click.native="collapse" draggable="false"><i class="fab fa-stack-exchange"></i><span>{{ $t('message.contact') }}</span></router-link>
        </li>
        <li v-if="!hasVaultProfile('USER', currentVault)">
          <router-link to="/mentions-legales" @click.native="collapse" draggable="false"><i class="fas fa-list"></i><span>{{ $t('message.legal_notices') }}</span></router-link>
        </li>
        <li v-if="user && !hasVaultProfile('USER', currentVault)">
          <router-link to="/cgu" @click.native="collapse" draggable="false"><i class="far fa-check-circle"></i><span>{{ $t('message.cgu') }}</span></router-link>
        </li>
      </section>

      <section v-if="user && !hasVaultProfile('USER', currentVault)">
        <h1>
          <i class="fas fa-cloud" style="margin-right: 5px;"></i>
          {{ $t('message.personal_storage_space') }}
        </h1>
        <li class="pt-5">
          <div class="progress-container">
            <div class="progress-background">
              <div :class='"progress " + resolveProgressColor()' :style='"width:" + resolvePercentUsage() + "%"'>
              </div>
            </div>
          </div>
          <p class="progress-text">
            {{ $t('message.usage', { used: resolveCurrentUsage(), max: resolveMaxUsage() }) }}
            ({{ $t('message.percent_used', { percent: resolvePercentUsage() }) }})<br>
            {{ getNumberOfFiles() }}
          </p>
        </li>
        <div>
          <a v-if="user && user.rcu" class="buy-storage-action" :href='getUpgradeStorageUrl()' target="_blank" draggable="false">{{ $t('message.buy_storage') }}</a>
        </div>
      </section>
    </ul>
  </nav>
</template>

<script>
import slugify from 'slugify'
import config from '../config'

export default {
  name: 'MenuLeft',
  computed: {
    isCasUser () {
      return this.$store.getters.isCasUser
    },
    windowWidth () {
      return this.$store.getters.getWindowWidth
    },
    authenticated () {
      return this.$store.getters.isAuthenticated
    },
    currentVault () {
      return this.$store.getters.getCurrentVault
    },
    currentRootfolders () {
      return this.$store.getters.getCurrentRootFolders
    },
    user () {
      return this.$store.getters.getUser
    },
    currentCompany () {
      return this.$store.getters.getCurrentCompany
    },
    vaultDiskUsage () {
      return this.$store.getters.getVaultDiskUsage
    },
    numberOfFiles () {
      return this.$store.getters.getNumberOfFiles
    },
    isBudgetUser () {
      return (this.$store.getters.isBudgetUser || ['LoginBudget', 'ContactBudget'].includes(this.$route.name.toString()))
    }
  },
  mounted () {
    this.handleSidebar()

    if (this.authenticated) {
      this.timer = setInterval(() => {
        this.refreshUser()
      }, 30000)
    }
  },
  sockets: {
    documentDone (document) {
      setTimeout(() => {
        this.$store.dispatch('getVaultDiskUsage', { id: document.vaultId })
        this.$store.dispatch('getNumberOfFiles', { id: document.vaultId })
      }, 3000)
    },
    documentRemoved (document) {
      if (this.currentVault.id === document.vaultId) {
        setTimeout(() => {
          this.$store.dispatch('getVaultDiskUsage', { id: document.vaultId })
          this.$store.dispatch('getNumberOfFiles', { id: document.vaultId })
        }, 3000)
      }
    },
    storageUpdate () {
      setTimeout(() => {
        this.$store.dispatch('getVaultDiskUsage', { id: this.vaultDiskUsage.vault })
      }, 3000)
    },
  },
  watch: {
    windowWidth () {
      this.handleSidebar()
    }
  },
  methods: {
    handleSidebar () {
      const sidebar = document.querySelector('#sidebar')

      if (this.windowWidth > 992) {
        sidebar.classList.add('stretched')
      } else {
        sidebar.classList.remove('stretched')
      }
    },
    collapse () {
      const sidebar = document.querySelector('#sidebar')

      if (this.windowWidth < 992) {
        sidebar.classList.remove('stretched')
      }
    },
    slugify (name) {
      if (!name) {
        return
      }

      return slugify(name, { lower: true })
    },
    hasProfile (profile) {
      return this.$store.getters.hasProfile(profile)
    },
    hasVaultProfile (profile) {
      if (this.currentVault) {
        const vaultsRole = this.currentVault.vaultsRole

        for (let i = 0; i < vaultsRole.length; i++) {
          if (vaultsRole[i].profiles.includes(profile)) {
            return true
          }
        }
      }

      return false
    },
    hasRole (role, company = null) {
      return this.$store.getters.hasRole(role, company ? company.id : null)
    },
    hasDocumentSectionAccess () {
      if (!this.hasSecretSectionAccess() && this.currentRootfolders.length === 1) {
        return true
      } else if (this.hasSecretSectionAccess() && this.currentRootfolders.length === 1) {
        return false
      } else {
        return this.currentRootfolders.length > 1
      }
    },
    hasSecretSectionAccess () {
      for (let i = 0; i < this.currentRootfolders.length; i++) {
        const currentRootFolder = this.currentRootfolders[i]

        if (currentRootFolder.section.type === 'SECRET') {
          return true
        }
      }

      return false
    },
    refreshUser () {
      if (this.user) {
        this.$store.dispatch('socketLoaded', this.user)
      }
    },
    resolveCurrentUsage () {
      if (this.vaultDiskUsage) {
        const currentUsage = parseInt(this.vaultDiskUsage.currentUsage)

        return currentUsage === 0 ? currentUsage : (currentUsage / 1000000).toFixed(2)
      }

      return 0
    },
    resolveMaxUsage () {
      if (this.vaultDiskUsage) {
        const maxUsage = parseInt(this.vaultDiskUsage.maxUsage)

        return maxUsage === 0 ? maxUsage : (maxUsage / 1000000000)
      }

      return 0
    },
    resolvePercentUsage () {
      if (this.vaultDiskUsage) {
        const currentUsage = parseInt(this.vaultDiskUsage.currentUsage)
        const maxUsage = parseInt(this.vaultDiskUsage.maxUsage)

        return (currentUsage * 100 / maxUsage).toFixed(2)
      }

      return 0
    },
    resolveProgressColor () {
      const currentPercentage = this.resolvePercentUsage()

      if (currentPercentage < 50) {
        return 'green'
      }

      if (currentPercentage >= 50 && currentPercentage < 90) {
        return 'orange'
      }

      if (currentPercentage >= 90) {
        return 'red'
      }
    },
    getNumberOfFiles () {
      const count = this.numberOfFiles ? this.numberOfFiles.count : 0
      const key = count > 1 ? 'message.file_plural' : 'message.file_singular'

      return this.$t(key, { count: count })
    },
    getUpgradeStorageUrl () {
      return config.upgradeOptionsUrl
    }
  }
}
</script>
