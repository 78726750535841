<template>
  <nav id="topbar" class="navbar-rcu">
    <div id="btn-burger-toggle" @click="collapse()">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <ul>
      <li>
        <a href="https://www.fiducial.fr" target="_blank" rel="noopener noreferrer" class="dropdown-toggle-none">
          Fiducial.fr
        </a>
      </li>

      <li>
        <a class="dropdown-toggle" href="#" id="other-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" draggable="false">
          <span id="current-other">{{ $t('message.others') }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-left animated--grow-in" aria-labelledby="other-dropdown">
          <div v-for="(other, index) in otherMenus" :key="index">
            <a class="dropdown-item" :href="other.uri" target="_blank" draggable="false">
              <i class="fas fa-building fa-sm mr-2 text-gray-400"></i>
              <span class="other-choice">{{ other.title }}</span>
            </a>
          </div>
        </div>
      </li>
    </ul>

<!--    <v-col cols="3">-->
<!--      <the-searchbar dark></the-searchbar>-->
<!--    </v-col>-->

    <ul>
      <li>
        <a class="dropdown-toggle" href="#" id="service-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" draggable="false">
          <i class="fas fa-th mr-2"></i>
          <span id="current-service">{{ $t('message.services') }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-left animated--grow-in" aria-labelledby="service-dropdown">
          <div class="flex-row services-row">
            <div class="service-item" v-for="(habilitation, index) in habilitations" :key="'h' + index">
              <a class="service-link" :href="redirectUrl + '/' + habilitation.applicationCode" target="_blank" draggable="false">
                <img v-bind:src="require('@/assets/images/services/' + habilitation.picto)" class="img-responsive center-block">
                <span class="other-choice">{{ habilitation.title }}</span>
              </a>
            </div>
          </div>
          <p class="service-discover">
            <span>{{ $t('message.discover_also') }}</span>
          </p>
          <div class="flex-row services-row">
            <div class="service-item" v-for="(application, index) in applicationsToDiscover" :key="'a' + index">
              <a class="service-link" :href="application.uri" target="_blank" draggable="false">
                <img v-bind:src="require('@/assets/images/services/' + application.picto)" class="img-responsive center-block">
                <span class="other-choice">{{ application.title }}</span>
              </a>
            </div>
          </div>

          <!--img src="../assets/images/logo-Mon-Ecoffre-full-blue.svg"  alt="Logo Mon ecoffre"-->

        </div>
      </li>

      <li v-if="authenticated && currentVault">
        <a :class="vaults.length > 1 ? 'dropdown-toggle' : 'dropdown-toggle-none'" href="#" id="company-dropdown" role="button" :data-toggle="vaults.length > 1 ? 'dropdown' : ''" aria-haspopup="true" aria-expanded="false" draggable="false">
          <i id="current-company-icon" class="fas fa-building text-gray-400"></i>
          <span id="current-company">{{ currentVault.type.label + " - " + getVaultTitle(currentVault) }}</span>
        </a>
        <div v-if="vaults.length > 1" class="dropdown-menu dropdown-menu-left animated--grow-in" aria-labelledby="company-dropdown">
          <div id="current-company-sm">
            {{ currentVault.type.label + " - " + getVaultTitle(currentVault) }}
          </div>
          <div v-for="(vault, index) in vaults" :key="index">
            <a class="dropdown-item" href="#" v-on:click="updateVault(vault)" draggable="false">
              <i class="fas fa-building fa-sm mr-2 text-gray-400"></i>
              <span class="company-choice">{{ vault.type.label + " - " + getVaultTitle(vault) }}</span>
            </a>
          </div>
        </div>
      </li>

      <li>
        <a
          :class="availableLocales.length > 1 ? 'dropdown-toggle' : 'dropdown-toggle-none'"
          class="text--black ml-7"
          href="#"
          id="company-dropdown"
          role="button"
          :data-toggle="availableLocales.length > 1 ? 'dropdown' : ''"
          aria-haspopup="true"
          aria-expanded="false"
          draggable="false"
        >
          <i id="current-company-icon" class="fas fa-language text-gray-600"></i>
          <span id="current-company">{{ currentLocale }}</span>
        </a>
        <div v-if="availableLocales.length > 1" class="dropdown-menu dropdown-menu-left animated--grow-in" aria-labelledby="company-dropdown" >
          <div id="current-company-sm">
            {{ currentLocale }}
          </div>
          <div v-for="(locale, index) in availableLocales" :key="index">
            <a class="dropdown-item" href="#" v-on:click="updateLocale(locale)" draggable="false">
              <i class="fas fa-language fa-sm mr-2"></i>
              <span class="company-choice">{{ locale.toUpperCase() }}</span>
            </a>
          </div>
        </div>
      </li>

      <li class="dropdown">
        <a class="dropdown-toggle" href="#" id="company-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" draggable="false">
          <i class="far fa-user mr-2"></i>
          <span id="current-user">{{ user.email }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-left animated--grow-in" aria-labelledby="company-dropdown">
          <div>
            <a class="dropdown-item" :href='getAccountUrl()' target="_blank" draggable="false">
              <i class="fas fa-user fa-sm mr-2 text-gray-400"></i>
              {{ $t('message.my_account') }}
            </a>
            <a  v-if="hasProfile('VAULT') && hasRole('ROLE_LEADER', currentCompany)" class="dropdown-item" :href='getCollaboratorUrl()' target="_blank" draggable="false">
              <i class="fas fa-user-friends fa-sm mr-2 text-gray-400"></i>
              {{ $t('message.my_collaborators') }}
            </a>
            <router-link v-if="hasProfile('VAULT') && hasRole('ROLE_LEADER', currentCompany)" to="/options-de-notifications" class="dropdown-item" role="button" draggable="false">
              <i class="fas fa-bell fa-sm mr-2 text-gray-400"></i>
              {{ $t('message.notification_options') }}
            </router-link>
          </div>
        </div>
      </li>

      <li class="dropdown">
        <a class="dropdown-toggle-none" v-on:click="logout()" id="logout-dropdown" role="button" aria-haspopup="true" aria-expanded="false" v-if="authenticated" draggable="false">
          <i class="fas fa-power-off mr-2"></i>
          <span id="disconnect">{{ $t('message.log_out') }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import config from '../config'
import TheSearchbar from '@/components/TheSearchbar';
import Vue from 'vue'

export default {
  name: 'MenuTopRcu',
  components: { TheSearchbar },
  data: () => ({
    timer: null,
    otherMenus: [
      {
        title: 'Banque FIDUCIAL',
        uri: 'http://www.banque-fiducial.fr/'
      },
      {
        title: 'VEOPRINT',
        uri: 'http://www.veoprint.com/'
      },
      {
        title: 'FIDUCIAL Office Solutions',
        uri: 'https://www.fiducial-office-solutions.fr/'
      },
      {
        title: 'FIDUCIAL Sofiral',
        uri: 'http://www.fiducial-sofiral-avocats.fr/'
      },
      {
        title: 'FIDUCIAL Gérance',
        uri: 'http://www.fiducial-gerance.fr/'
      },
      {
        title: 'AutoEntreprenons.fr',
        uri: 'http://www.autoentreprenons.fr/'
      },
      {
        title: 'CGA FRANCE',
        uri: 'http://www.cga-france.fr/'
      },
      {
        title: 'AGA-PL FRANCE',
        uri: ' http://www.aga-pl-france.fr/'
      }
    ],
    applicationsToDiscover: [
      {
        title: 'Banque FIDUCIAL',
        uri: 'http://www.banque-fiducial.fr/',
        picto: 'picto-banque.png'
      },
      {
        title: 'FIDUCIAL Office Solutions',
        uri: 'https://www.fiducial-office-solutions.fr/',
        picto: 'picto-fos.png'
      },
      {
        title: 'Veoprint',
        uri: 'http://www.veoprint.com/',
        picto: 'picto-veoprint.png'
      }
    ]
  }),
  computed: {
    authenticated () {
      return this.$store.getters.isAuthenticated
    },
    user () {
      return this.$store.getters.getUser
    },
    vaults () {
      return this.$store.getters.getVaults
    },
    currentVault () {
      return this.$store.getters.getCurrentVault
    },
    currentCompany () {
      return this.$store.getters.getCurrentCompany
    },
    habilitations () {
      return this.$store.getters.getHabilitations
    },
    redirectUrl () {
      return config.logoutUrl.replace('logout', 'redirect-to-app')
    },
    currentLocale () {
      return this.$store.getters.getLocale
    },
    availableLocales () {
      return this.$store.getters.getAvailableLocales
    }
  },
  methods: {
    collapse () {
      const sidebar = document.querySelector('#sidebar')

      sidebar.classList.toggle('stretched')
    },
    updateVault (vault) {
      this.$store.commit('setCurrentVault', vault)
      this.$store.commit('setCurrentCompany', vault.company)

      this.$store.dispatch('getVaultDiskUsage', vault)
      this.$store.dispatch('getNumberOfFiles', vault)
      this.$store.dispatch('getCurrentRootFolders')
    },
    logout () {
      this.$store.dispatch('logout', 'rcu')
    },
    hasProfile (profile) {
      return this.$store.getters.hasProfile(profile)
    },
    hasRole (role, company = null) {
      return this.$store.getters.hasRole(role, company ? company.id : null)
    },
    getAccountUrl () {
      return config.accountUrl
    },
    getCollaboratorUrl () {
      return config.collaboratorUrl
    },
    getVaultTitle (vault) {
      return vault.budgetLabel || vault.budgetCode || vault.company.name
    },
    updateLocale (locale) {
      if (!this.authenticated) {
        this.$i18n.locale = locale
        this.$store.commit('setLocale', locale)
        this.$router.go()

        return
      }

      const userId = this.$store.getters.getUser.id

      const data = {
        locale: locale.toLowerCase()
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/users/' + userId, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.$store.commit('setLocale', locale)
              this.$i18n.locale = locale

              this.$router.go()
            }

            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
