<template>
  <v-text-field
    v-model="searchString"
    :placeholder="$t('message.search')"
    clearable
    :color="dark ? 'white' : 'primary'"
    :class="dark ? 'theme--dark search-bar' : ''"
    @keydown.enter="searchItems"
    @click:prepend="searchItems"
    @click:clear="clearItems"
    prepend-icon="fa fa-search"
  />
</template>

<script>
export default {
  name: 'Searchbar',
  props: {
    dark: {
      type: Boolean,
      defaults: false
    }
  },
  data () {
    return {
      searchString: this.$route.params.searchString
    }
  },
  methods: {
    searchItems: function () {
      if (this.searchString !== this.$route.params.searchString) {
        this.$router.push({ name: 'Search', params: { searchString: this.searchString } })
      }
    },
    clearItems: function () {
      this.searchString = ''
      const home = this.$router.resolveHome(this.$store)
      this.$router.push(home)
    }
  }
}
</script>

<style>
.theme--dark.search-bar button{
  color: white!important;
}
</style>
