<template>
  <nav id="topbar" class="navbar">
    <div id="btn-burger-toggle" @click="collapse()">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <v-col cols="6" md="6" lg="4" v-if="authenticated && currentVault">
      <the-searchbar></the-searchbar>
    </v-col>

    <ul>
      <li v-if="authenticated && currentVault">
        <a
          :class="availableLocales.length > 1 ? 'dropdown-toggle' : 'dropdown-toggle-none'"
          class="text--black"
          href="#"
          id="company-dropdown"
          role="button"
          :data-toggle="availableLocales.length > 1 ? 'dropdown' : ''"
          aria-haspopup="true"
          aria-expanded="false"
          draggable="false"
        >
          <i id="current-company-icon" class="fas fa-building text-gray-600"></i>
          <span id="current-company">{{ getVaultTitle(currentVault) }}</span>
        </a>
        <div v-if="vaults.length > 1" class="dropdown-menu dropdown-menu-left animated--grow-in" aria-labelledby="company-dropdown" >
          <div id="current-company-sm">
            {{ getVaultTitle(currentVault) }}
          </div>
          <div v-for="(vault, index) in getOrdenedVaults" :key="index">
            <a class="dropdown-item" href="#" v-on:click="updateVault(vault)" draggable="false">
              <i class="fas fa-building fa-sm mr-2"></i>
              <span class="company-choice">{{ getVaultTitle(vault) }}</span>
            </a>
          </div>
        </div>
      </li>

      <li>
        <a
          :class="availableLocales.length > 1 ? 'dropdown-toggle' : 'dropdown-toggle-none'"
          class="text--black ml-7"
          href="#"
          id="company-dropdown"
          role="button"
          :data-toggle="availableLocales.length > 1 ? 'dropdown' : ''"
          aria-haspopup="true"
          aria-expanded="false"
          draggable="false"
        >
          <country-flag :country='getFlagAlpha(currentLocale)' size='small'/>
          <span id="current-company" class="pl-1"> {{ currentLocale }}</span>
        </a>
        <div v-if="availableLocales.length > 1" class="dropdown-menu dropdown-menu-left animated--grow-in" aria-labelledby="company-dropdown" >
          <div id="current-company-sm">
            {{ currentLocale }}
          </div>
          <div v-for="(locale, index) in availableLocales" :key="index">
            <a class="dropdown-item" href="#" v-on:click="updateLocale(locale)" draggable="false">
              <country-flag :country='getFlagAlpha(locale.toUpperCase())' size='small'/>
              <span class="company-choice pl-1">{{ locale.toUpperCase() }}</span>
            </a>
          </div>
        </div>
      </li>

      <li class="dropdown">
        <router-link to="/connexion" class="dropdown-toggle" v-if="!authenticated && !$store.getters.isBudgetUser">
          {{ $t('message.login') }}
          <div class="img-profile"><i class="far fa-user"></i></div>
        </router-link>

        <router-link to="/connexion-code-budget" class="dropdown-toggle" v-if="!authenticated && $store.getters.isBudgetUser">
          {{ $t('message.login') }}
          <div class="img-profile"><i class="far fa-user"></i></div>
        </router-link>

        <a
          v-if="authenticated"
          class="dropdown-toggle text-black"
          href="#"
          id="user-dropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          draggable="false"
        >
          <span id="current-user">{{ user.email }}</span>
          <div class="img-profile bg-gray-600"><i class="far fa-user"></i></div>
        </a>
        <div class="dropdown-menu dropdown-menu-right animated--grow-in" aria-labelledby="user-dropdown" v-if="authenticated">
          <div id="current-user-sm">
            {{ user.email }}
          </div>
          <router-link
            v-if="!user.rcu && !$store.getters.isBudgetUser"
            to="/modification-email"
            class="dropdown-item"
            role="button"
            draggable="false"
          >
            <i class="fas fa-edit fa-sm mr-2 text-gray-400"></i>
            {{ $t('message.edit_your_email') }}
          </router-link>

          <router-link
            v-if="!user.rcu && !$store.getters.isBudgetUser"
            to="/modification-mot-de-passe"
            class="dropdown-item"
            role="button"
            draggable="false"
          >
            <i class="fas fa-edit fa-sm mr-2 text-gray-400"></i>
            {{ $t('message.edit_your_password') }}
          </router-link>

          <router-link v-if="hasProfile('VAULT') && (!user.rcu || $store.getters.isBudgetUser)" to="/options-de-notifications" class="dropdown-item" role="button" draggable="false">
            <i class="fas fa-bell mr-2 text-gray-400"></i>
            {{ $t('message.notification_options') }}
          </router-link>

          <router-link
            v-if="hasProfile('VAULT') && !user.rcu && !$store.getters.isBudgetUser"
            to="/souscription-aux-services"
            class="dropdown-item"
            role="button"
            draggable="false"
          >
            <i class="far fa-check-circle mr-2 text-gray-400"></i>
            <span>{{ $t('message.subscription_services') }}</span>
          </router-link>

          <router-link
            v-if="hasProfile('VAULT') && !user.rcu && !$store.getters.isBudgetUser"
            to="/tiers-de-confiance"
            class="dropdown-item"
            role="button"
            draggable="false"
          >
            <i class="fas fa-id-badge mr-2 text-gray-400"></i><span>&nbsp;&nbsp;{{ $t('message.trusted_third_party') }}</span>
          </router-link>

          <router-link
            v-if="hasProfile('VAULT') && !user.rcu && !$store.getters.isBudgetUser"
            to="/2fa-qr-code"
            class="dropdown-item"
            role="button"
            draggable="false"
          >
            <i class="fas fa-shield-alt mr-2 text-gray-400"></i><span>&nbsp;{{ $t('message.two_factor_authentication') }}</span>
          </router-link>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" v-on:click="logout()">
            <i class="fas fa-sign-out-alt fa-fw mr-2 text-gray-400"></i>
            {{ $t('message.log_out') }}
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import Vue from 'vue'
import config from '@/config'
import TheSearchbar from '@/components/TheSearchbar.vue'
import CountryFlag from 'vue-country-flag'

export default {
  name: 'MenuTop',
  components: { TheSearchbar, CountryFlag },
  data: () => ({
    timer: null
  }),
  computed: {
    authenticated () {
      return this.$store.getters.isAuthenticated
    },
    user () {
      return this.$store.getters.getUser
    },
    vaults () {
      return this.$store.getters.getVaults
    },
    currentVault () {
      return this.$store.getters.getCurrentVault
    },
    token () {
      return this.$store.getters.getToken
    },
    getOrdenedVaults () {
      const temp = this.vaults
      return temp.sort((a, b) => {
        if (!a.budgetCode) {
          return -1
        }
        if (!b.budgetCode) {
          return 1
        }
        return a.budgetCode.localeCompare(b.budgetCode)
      })
    },
    currentLocale () {
      return this.$store.getters.getLocale
    },
    availableLocales () {
      return this.$store.getters.getAvailableLocales
    }
  },
  methods: {
    getFlagAlpha (val) {
      // https://www.npmjs.com/package/vue-country-flag#available-flags
      const dictionary = { EN: 'gb', FR: 'fr' }
      return dictionary[val]
    },
    collapse () {
      const sidebar = document.querySelector('#sidebar')

      sidebar.classList.toggle('stretched')
    },
    updateVault (vault) {
      if (this.$store.getters.isBudgetUser) {
        this.postVaultLogs(this.currentVault, 'Déconnexion du coffre', this.user, this.token)
      }
      this.$store.commit('setCurrentVault', vault)
      this.$store.commit('setCurrentCompany', vault.company)

      this.$store.dispatch('getVaultDiskUsage', vault)
      this.$store.dispatch('getNumberOfFiles', vault)
      this.$store.dispatch('setRoute')
    },
    logout () {
      this.$store.dispatch('logout')
    },
    hasProfile (profile) {
      return this.$store.getters.hasProfile(profile)
    },
    getVaultTitle (vault) {
      if (vault.budgetCode) {
        return vault.budgetCode + ' - ' + (vault.budgetLabel || '-')
      }

      return vault.type.label + ' - ' + vault.company.name
    },
    postVaultLogs: (vault, action, user, token) => {
      const data = {
        vault: { id: vault.id },
        user: { id: user.id },
        action: action,
        browser: '',
        ip: '',
        createdAt: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/logs', formData, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        .then(response => {
          if (response.status === 200) {
            return response
          }
        })
        .catch(e => {
        })
    },
    updateLocale (locale) {
      if (!this.authenticated) {
        this.$i18n.locale = locale
        this.$store.commit('setLocale', locale)
        this.$router.go()

        return
      }

      const userId = this.$store.getters.getUser.id

      const data = {
        locale: locale.toLowerCase()
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/users/' + userId, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.$store.commit('setLocale', locale)
              this.$i18n.locale = locale

              this.$router.go()
            }

            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
<!--<style scoped>
@media (min-width: 992px){
  #topbar {
    justify-content: space-between;
  }
}
</style>-->
