import Vue from 'vue'

Vue.filter('dateFormat', date => {
  if (!date) return null

  const d = new Date(date)
  if (!(d instanceof Date && !isNaN(d))) {
    if (date.length === 10 && date.split('/').length === 3) {
      return date
    }
  }
  let month = String(d.getMonth() + 1)
  let day = String(d.getDate())
  const year = String(d.getFullYear())

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return `${day}/${month}/${year}`
})

Vue.filter('hourFormat', date => {
  if (!date) return null

  const d = new Date(date)
  let minute = String(d.getMinutes())
  let hour = String(d.getHours())

  if (minute.length < 2) minute = '0' + minute
  if (hour.length < 2) hour = '0' + hour

  return `${hour}:${minute}`
})
